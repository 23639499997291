import React, { ElementType, useState, useRef, Fragment, ReactElement, useMemo, ReactNode } from 'react';
import Nav from '@molecules/Nav';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { RouteComponentProps } from 'react-router';
import Notification from './Notification';
import { ReactComponent as IconMap } from '@assets/iconMap.svg';
import { ReactComponent as IconList } from '@assets/iconList.svg';
import { ReactComponent as IconArrowBack } from '@assets/iconArrowBack.svg';

export interface LayoutProps {
  title: string;
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}

const Header = styled.header`
  min-height: 44px;
  text-align: center;
  top: 0;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  flex-wrap: wrap;
  background-color: white;
  z-index: 2;
`;

const HeaderSpace = styled.div`
  margin-bottom: 44px;
`;

const Title = styled.h1`
  font-size: 17px;
  font-weight: 400;
  color: ${themeGet('colors.black')};
  margin: 0;
  line-height: 44px;
  display: inline-block;
  flex: 4;
`;

const Container = styled.main`
  background-color: ${themeGet('colors.background')};
  min-height: 100%;
`;

const SuffixContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const PrefixContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const HeaderPortal = styled.div`
  flex-basis: 100%;
`;

type PageConfigProps = {
  [key: string]: {
    suffix?: ElementType;
    onSuffixClick?: () => void;
    disableBack?: boolean;
  };
};

const getPageConfig = (history: RouteComponentProps['history']) => ({
  '/biker-pool': {
    suffix: IconMap,
    onSuffixClick: () => {
      history.push('/pool-map');
    },
    disableBack: true,
  },
  '/pool-map': {
    suffix: IconList,
    onSuffixClick: () => {
      history.push('/biker-pool');
    },
  },
  '/dispatch-list': {
    suffix: IconMap,
    onSuffixClick: () => {
      history.push('/dispatch-map');
    },
    disableBack: true,
  },
  '/dispatch-map': {
    suffix: IconList,
    onSuffixClick: () => {
      history.push('/dispatch-list');
    },
  },
  '/account': {
    disableBack: true,
  },
});

const Layout: React.FC<LayoutProps> = ({ location, title, history, children }) => {
  const pageConfig = useMemo<PageConfigProps>(() => getPageConfig(history), [history]);
  const Suffix: ElementType | undefined = pageConfig?.[location.pathname]?.suffix;
  const onSuffixClick = pageConfig?.[location.pathname]?.onSuffixClick;
  const disableBack = pageConfig?.[location.pathname]?.disableBack || false;
  const [headerPortal, setHeaderPortal] = useState<HTMLDivElement | null>(null);

  return (
    <Fragment>
      <Header>
        <PrefixContainer onClick={() => (disableBack ? undefined : history?.goBack())}>
          {!disableBack && <IconArrowBack />}
        </PrefixContainer>
        <Title>{title}</Title>
        <SuffixContainer onClick={onSuffixClick}>{Suffix && <Suffix />}</SuffixContainer>
        <HeaderPortal
          ref={(ref) => {
            const isFirst = !headerPortal;
            if (isFirst && ref) {
              setHeaderPortal(ref);
            }
          }}
        />
      </Header>
      <HeaderSpace />
      <Container>
        {Array.isArray(children) &&
          React.Children?.map(
            children,
            (c: ReactNode, i) => React.isValidElement(c) && React.cloneElement(c, { headerPortal, key: i })
          )}
      </Container>
      <Nav currentPath={location?.pathname || ''} />
      <Notification />
    </Fragment>
  );
};

export default Layout;
