import { EventConfig } from "~/definition/eventConfig";
import { Worker } from "~/definition/worker";
import { kottos } from './BikerAxios'


export const getProfile = () => {
  return kottos.get<{ data: Worker }>(`/v2/workers/me`).then(({ data }) => data.data);
};

export const getEventConfigs = () => {
  return kottos.get<{ data: EventConfig }>(`/talos/v1/workers/events/config`).then(({ data }) => data.data);
};

export const postEventConfig = (config: { lat: number; lng: number }) => {
  return kottos.post(`/talos/v1/workers/events`, config);
};
