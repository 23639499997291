import TodayList from "@pages/TodayList";
import BikerPool from "@pages/BikerPool";
import DispatchMap from "@pages/DispatchMap";
import DispatchList from "@pages/DispatchList";
import PoolMap from "@pages/PoolMap";
import DispatchForm from "@pages/DispatchForm";
import DispatchView from "@pages/DispatchView";
import MissingScooterReport from "@pages/MissingScooterReport";
import AbnormalReport from "@pages/AbnormalReport";
import Account from "@pages/Account";

// TODO: how to type this?
export const getRoutes = (t) => [
  { title: t("biker.todayList"), path: "/today-list", component: TodayList },
  { title: t("biker.bikerPool"), path: "/biker-pool", component: BikerPool },
  { title: t("biker.poolMap"), path: "/pool-map", component: PoolMap },
  {
    title: t("biker.dispatchList"),
    path: "/dispatch-list",
    component: DispatchList,
  },
  {
    title: t("biker.dispatchMap"),
    path: "/dispatch-map",
    component: DispatchMap,
  },
  {
    title: t("biker.task"),
    path: "/dispatch/:id/form",
    component: DispatchForm,
  },
  {
    title: t("biker.viewTask"),
    path: "/dispatch/:id/view",
    component: DispatchView,
  },
  {
    title: t("biker.missingScooterReport"),
    path: "/:type/:id/missing-scooter-report",
    component: MissingScooterReport,
  },
  {
    title: t("biker.abnormalReport"),
    path: "/:type/:id/abnormal-report",
    component: AbnormalReport,
  },
  { title: t("biker.account"), path: "/account", component: Account },
];
