import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { setUnAcceptedOrders, selectUnAcceptedOrders } from '@redux/ownedOrders';
import CardOrderItem from '@organisms/CardOrderItem';
import Count from '@atoms/Count';
import Button from '@atoms/Button';
import { confirmOrdersByIds } from '@apis/dispatch';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { enqueueNotification } from '@redux/notification';

export interface TodayListProps {
  history: RouteComponentProps['history'];
}

const CardContainer = styled.div`
  padding: 8px 0;
`;

const AcceptContainer = styled.div`
  padding: 4px;
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
`;
const CountContainer = styled.div`
  background-color: white;
`;

const TodayList: React.FC<TodayListProps> = ({ history }) => {
  const { unAcceptedOrders, unAcceptedCount } = useSelector(selectUnAcceptedOrders);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const acceptTask = async () => {
    try {
      await confirmOrdersByIds(unAcceptedOrders.map(({ id }) => id));
      dispatch(setUnAcceptedOrders({ orders: [] }));
      history.replace('/dispatch-list');
    } catch (error) {
      enqueueNotification({ message: t('biker.addFailed'), duration: 2000, type: 'warning' });
    }
  };

  useEffect(() => {
    if (unAcceptedCount === 0) {
      history.replace('/dispatch-list');
    }
  }, []);

  return (
    <div>
      <CountContainer>
        <Count count={unAcceptedCount} type="assign" />
      </CountContainer>
      <CardContainer>
        {unAcceptedOrders.map((order) => (
          <CardOrderItem key={order.id} orderInfo={order} isAddMode={false} />
        ))}
      </CardContainer>
      <AcceptContainer>
        <Button
          label={t('biker.comfirmList')}
          shape="round"
          width={1}
          onClick={acceptTask}
          disabled={unAcceptedCount === 0}
        />
      </AcceptContainer>
    </div>
  );
};

export default TodayList;
