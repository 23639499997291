import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectHasJustLogin, updateLoginFlag } from '@redux/appStatus';
import { selectUnAcceptedCount } from '@redux/ownedOrders';
import { getRoutes } from '@configs/routes';
import Snackbar from '@atoms/Snackbar';
import Layout from './Layout';

interface AppInnerProps {
  location: RouteComponentProps['location'];
  history: RouteComponentProps['history'];
}

const AppInner: React.FC<AppInnerProps> = ({ history, location }) => {
  const { t } = useTranslation();
  const unAcceptedCount = useSelector(selectUnAcceptedCount);
  const hasJustLogin = useSelector(selectHasJustLogin);
  const [isSnackVisible, setIsSnackVisible] = useState<boolean>(false);
  const routes = useMemo(() => getRoutes(t), [t]);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (hasJustLogin && unAcceptedCount !== null && location.pathname === '/') {
      if (unAcceptedCount > 0) {
        history.push('/today-list');
      } else {
        history.push('/dispatch-list');
      }
      dispatch(updateLoginFlag());
    } else if (unAcceptedCount !== null && unAcceptedCount > 0) {
      if (location.pathname === '/dispatch-list' || location.pathname === '/') {
        history.push('/today-list');
      } else if (location.pathname !== '/today-list') {
        setIsSnackVisible(true);
      }
    }
    if (location.pathname === '/today-list') {
      setIsSnackVisible(false);
    }
  }, [location.pathname, unAcceptedCount, hasJustLogin]);

  return (
    <Switch>
      {routes.map((route) => {
        const Comp = route.component;
        return (
          <Route
            exact
            path={route.path}
            key={route.path}
            render={(props) => (
              <Layout title={route.title} location={props.location} history={props.history}>
                <Comp {...props} />
                <Snackbar
                  onClose={() => setIsSnackVisible(false)}
                  visible={isSnackVisible}
                  duration={0}
                  message={t('biker.newOrderNotification')}
                  suffix={t('biker.check')}
                  onSuffixClick={() => history.push('/today-list')}
                />
              </Layout>
            )}
          />
        );
      })}
    </Switch>
  );
};

export default React.memo(AppInner);
