import React, { FC, useState, useEffect } from "react";
import ReactDOM from "react-dom";

type getContainerFunc = () => Element | null;

export interface PortalProps {
  getContainer?: string | Element | getContainerFunc | false | null;
}

export const PurePortal: FC<PortalProps> = props => {
  const { children, getContainer } = props;
  const [container, setContainer] = useState<Element | false | null>(document.body);

  useEffect(() => {
    if (getContainer === false) {
      setContainer(false);
    } else if (typeof getContainer === "string") {
      const element = document.querySelector(getContainer);
      if (element) setContainer(element);
    } else if (typeof getContainer === "function") {
      setContainer(getContainer());
    } else if (getContainer instanceof Element) {
      setContainer(getContainer);
    }
  }, [getContainer]);

  if (getContainer === false) {
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    return ReactDOM.createPortal(children, container || document.body);
  }
};

export default PurePortal;
