import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import isNil from "lodash.isnil";

import { RootState } from "./store";
import { getBikerPool, addPoolOrdersByIds, removeOrdersByIds, getOrderById } from "@apis/dispatch";
import { isPendingAction, isSucceededAction, isFailedAction } from "./utils";
import { Order } from "~/definition/order";
import { PinData } from "@molecules/MapInfo";
import { Location } from "~/definition/general";
import { AxiosError } from "axios";

export interface PoolOrdersState {
  loading: boolean;
  poolOrders: Order[];
  poolCount: number | null;
  currentOrder: Order | PinData | null;
  lastAddOrder: Order | null;
  mapPosition?: Location;
  filter: {
    scooterId: string; includeTaskItems: string[]
  }
}

const initialState: PoolOrdersState = {
  loading: false,
  poolOrders: [],
  poolCount: null,
  currentOrder: null,
  lastAddOrder: null,
  filter: {
    scooterId: '', includeTaskItems: []
  }
};

export const getOrders = createAsyncThunk(
  "poolOrders/getOrders",
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const position = state.poolOrders.mapPosition;
      const filter = state.poolOrders.filter;

      if (position) {
        const response = await getBikerPool({
          ...position,
          ...filter,
          radius: isNil(filter.scooterId) || !filter.scooterId ? 3500 : undefined,
        });
        return response;
      }
      return [];
    } catch (err) {
      return thunkApi.rejectWithValue("fail");
    }
  }
);

export const getCurrentOrder = createAsyncThunk("poolOrders/getCurrentOrder", async (id: string, thunkApi) => {
  try {
    const response = await getOrderById(id);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue("fail");
  }
});

interface MyKnownError extends AxiosError {
  message: string;
  isAxiosError: boolean
}

export const addOrder = createAsyncThunk<
  // Return type of the payload creator
  string,
  string,
  { rejectValue: MyKnownError | AxiosError }
>("poolOrders/addOrder", async (id: string, thunkApi) => {
  try {
    await addPoolOrdersByIds([id]);
    return id;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const removeLastOrder = createAsyncThunk("poolOrders/removeLastOrder", async (_, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    if (state.poolOrders.lastAddOrder) {
      const id = state.poolOrders.lastAddOrder.id;
      await removeOrdersByIds([id]);
      return id;
    }
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const poolOrdersSlice = createSlice({
  name: "poolOrders",
  initialState,
  reducers: {
    setPoolOrders: (state, action: PayloadAction<{ orders: Order[] }>) => {
      state.poolOrders = action.payload.orders;
      state.poolCount = action.payload.orders.length;
    },
    resetPoolOrders: state => {
      state.poolOrders = initialState.poolOrders;
      state.poolCount = initialState.poolCount;
    },
    setCurrentOrder: (state, action: PayloadAction<Order>) => {
      state.currentOrder = action.payload;
    },
    resetCurrentOrder: state => {
      state.currentOrder = null;
    },
    setMapPosition: (state, action: PayloadAction<Location>) => {
      state.mapPosition = action.payload;
    },
    setFilter: (state, action: PayloadAction<{ scooterId?: string; includeTaskItems?: string[] }>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
    resetFilter: state => {
      state.filter = initialState.filter
    }
  },
  extraReducers: builder => {
    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      if (payload?.length > 0) {
        state.poolOrders = payload;
        state.poolCount = payload.length;
      } else {
        state.poolOrders = [];
        state.poolCount = 0;
      }
    });
    builder.addCase(addOrder.fulfilled, (state, { payload }) => {
      state.lastAddOrder = state.poolOrders.filter(({ id }) => id === payload)[0];
      state.poolOrders = state.poolOrders.filter(({ id }) => id !== payload);
      state.poolCount = state.poolOrders.length;
      state.currentOrder = null;
    });
    builder.addCase(removeLastOrder.fulfilled, state => {
      if (state.lastAddOrder) {
        state.poolOrders.push(state.lastAddOrder);
      }
      state.poolCount = state.poolOrders.length;
    });
    builder.addCase(getCurrentOrder.fulfilled, (state, { payload }) => {
      state.currentOrder = payload;
    });
    builder.addMatcher(isPendingAction("poolOrders"), state => {
      state.loading = true;
    });
    builder.addMatcher(isSucceededAction("poolOrders"), state => {
      state.loading = false;
    });
    builder.addMatcher(isFailedAction("poolOrders"), state => {
      state.loading = false;
    });
  }
});

export const { setPoolOrders, resetPoolOrders, setCurrentOrder, resetCurrentOrder, setMapPosition, setFilter, resetFilter } = poolOrdersSlice.actions;

export const selectPoolOrders = (state: RootState): PoolOrdersState => state.poolOrders;

export const selectMapPosition = (state: RootState): Location | undefined => state.poolOrders.mapPosition;

export const selectPoolFilter = (state: RootState): {
  scooterId: string; includeTaskItems: string[]
} => state.poolOrders.filter;

export const selectUnAcceptedOrders = (
  state: RootState
): {
  poolOrders: Order[];
  poolCount: number | null;
  lastAddOrder: Order | null;
} => ({
  poolOrders: state.poolOrders.poolOrders,
  poolCount: state.poolOrders.poolCount,
  lastAddOrder: state.poolOrders.lastAddOrder
});

export const selectCurrentOrder = (state: RootState): Order | null => state.poolOrders.currentOrder;

export const selectPoolOrderLoading = (state: RootState): boolean => state.poolOrders.loading;

export default poolOrdersSlice.reducer;
