import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { useTranslation } from "react-i18next";

export interface CountProps {
  count?: number | null;
  className?: string;
  type: "assign" | "pool";
}

const Number = styled.div`
  margin: 0;
  display: inline-block;
  margin-right: 4px;
  color: ${themeGet(`colors.primary`)};
`;

const Container = styled.div`
  background: white;
  ${themeGet(`fonts.headline2`)};
  color: ${themeGet(`colors.black`)};
  padding: 13.5px;
  display: inline-block;
`;

const Count: React.FC<CountProps> = ({ count, className, type }) => {
  const { t } = useTranslation();

  return (
    <Container className={className}>
      <Number>{count === null ? "-" : count}</Number>
      {type === "assign" ? t("biker.assignedCount") : t("biker.poolCount")}
    </Container>
  );
};

export default Count;
