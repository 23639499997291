import React from "react";
import styled from "styled-components";
import Popup from "@atoms/Popup";
import { themeGet } from "@styled-system/theme-get";
import { useTranslation } from "react-i18next";

export interface PopupDoableOrderProps {
  visible: boolean;
  title?: string;
  onClose: () => void;
  onConfirm: () => void;
}

const Text = styled.span`
  ${themeGet("fonts.body1")};
  color: ${themeGet("colors.black")};
`;

const PopupDoableOrder: React.FC<PopupDoableOrderProps> = ({ visible, title, onClose, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Popup title={title} visible={visible} onClose={onClose} onConfirm={onConfirm}>
      <Text>{t("biker.doableInfo")}</Text>
    </Popup>
  );
};

export default PopupDoableOrder;
