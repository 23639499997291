import { AnyAction, AsyncThunk } from "@reduxjs/toolkit";

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk["pending"]>;

export const isPendingAction = (prefix: string) => {
  return (action: AnyAction): action is PendingAction => action.type.startsWith(prefix) && action.type.endsWith("/pending");
};

export const isSucceededAction = (prefix: string) => {
  return (action: AnyAction): action is PendingAction => action.type.startsWith(prefix) && action.type.endsWith("/fulfilled");
};

export const isFailedAction = (prefix: string) => {
  return (action: AnyAction): action is PendingAction => action.type.startsWith(prefix) && action.type.endsWith("/rejected");
};
