import React, { ComponentType, ReactNode } from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export interface ItemTextProps {
  Icon?: ComponentType | string;
  title?: ReactNode | string;
  subtitle?: string;
  text?: string | null;
  onClick?: () => void;
  className?: string;
  suffix?: string | ReactNode;
  shouldExpand?: boolean;
}

const Container = styled.div<{ shouldExpand: boolean }>`
  -webkit-tap-highlight-color: transparent;
  min-height: 50px;
  height: ${({ shouldExpand }) => (shouldExpand ? "auto" : "50px")};
  display: block;
  background-color: white;
  line-height: 50px;
  display: flex;
  flex-flow: nowrap;
  align-items: center;
`;

const InfoContainer = styled.div`
  display: inline-block;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  justify-content: center;
  flex: 1 1 auto;
  width: 60%;
`;

const Text = styled.div<{ shouldExpand: boolean }>`
  ${themeGet("fonts.body2")};
  color: ${themeGet("colors.secVariant")};
  line-height: 1;
  display: inline-block;
  white-space: ${({ shouldExpand }) => (shouldExpand ? "normal" : "nowrap")};
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LabelStyled = styled.div`
  ${themeGet("fonts.subtitle")};
  color: ${themeGet("colors.black")};
  line-height: 1.5;
  display: inline-block;
`;

const Subtitle = styled.div`
  ${themeGet("fonts.body2")};
  color: ${themeGet("colors.secVariant")};
  line-height: 1;
  display: inline-block;
  margin-left: 8px;
`;

const TitleContainer = styled.div`
  line-height: 1;
`;

const IconContainer = styled.div`
  flex-shrink: 0;
  line-height: 1;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SuffixContainer = styled.div`
  flex-shrink: 0;
  line-height: 1;
`;

const ItemText: React.FC<ItemTextProps> = ({
  Icon,
  title,
  subtitle,
  text,
  onClick,
  className,
  suffix,
  shouldExpand = false,
}) => {
  return (
    <Container
      onClick={onClick}
      className={className}
      shouldExpand={shouldExpand}
    >
      {Icon && (
        <IconContainer>
          {Icon &&
            (typeof Icon === "string" ? (
              <img src={Icon} alt={`${title} button icon`} />
            ) : (
              <Icon />
            ))}
        </IconContainer>
      )}
      <InfoContainer>
        <TitleContainer>
          {title && <LabelStyled>{title}</LabelStyled>}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </TitleContainer>
        {text && <Text shouldExpand={shouldExpand}>{text}</Text>}
      </InfoContainer>
      <SuffixContainer>{suffix}</SuffixContainer>
    </Container>
  );
};

export default ItemText;
