import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { isPendingAction, isSucceededAction, isFailedAction } from "./utils";
import {
  getParkingLots as getParkingLotsApi,
  openInParkingGate as openInParkingGateApi,
  openOutParkingGate as openOutParkingGateApi
} from "@apis/parkingLot";
import { RootState } from "./store";
import { ParkingLot } from "~/definition/parkinglot";

export interface ParkingLotsState {
  loading: boolean;
  parkingLots: ParkingLot[];
}

const initialState: ParkingLotsState = {
  parkingLots: [],
  loading: false
};

export const getParkingLots = createAsyncThunk("parkingLots/getParkingLots", async (_, thunkApi) => {
  try {
    const parkingLots = await getParkingLotsApi();
    return parkingLots;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const openInParkingGate = createAsyncThunk("parkingLots/openInParkingGate", async (id: string, thunkApi) => {
  try {
    const result = await openInParkingGateApi(id);
    return result;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const openOutParkingGate = createAsyncThunk("parkingLots/openOutParkingGate", async (id: string, thunkApi) => {
  try {
    const result = await openOutParkingGateApi(id);
    return result;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

const parkingLots = createSlice({
  name: "parkingLots",
  initialState,
  reducers: {
    resetParkingLots: state => {
      state.parkingLots = initialState.parkingLots;
    }
  },
  extraReducers: builder => {
    builder.addCase(getParkingLots.fulfilled, (state, { payload }) => {
      state.parkingLots = payload;
    });
    builder.addMatcher(isPendingAction("parkingLots"), state => {
      state.loading = true;
    });
    builder.addMatcher(isSucceededAction("parkingLots"), state => {
      state.loading = false;
    });
    builder.addMatcher(isFailedAction("parkingLots"), state => {
      state.loading = false;
    });
  }
});

export const { resetParkingLots } = parkingLots.actions;

export const selectParkingLots = (state: RootState): ParkingLotsState => state.parkingLots;

export default parkingLots.reducer;
