import React, { useState, useEffect, useRef, CSSProperties } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { themeGet } from '@styled-system/theme-get';
import { GoogleMap, Marker, useLoadScript, LoadScriptProps } from '@react-google-maps/api';
import { googleMapApiKey } from '@configs/.';
import PullUp from '@atoms/PullUp';
import Input from '@atoms/Input';
import Button from '@atoms/Button';
import IconLocation from '@assets/iconLocation.svg';
import { enqueueNotification } from '@redux/notification';
import { useDispatch } from 'react-redux';
import isNil from 'lodash.isnil';

type Position = { lat: number; lng: number };

export interface PullUpMapLocateProps {
  onChange?: (address: string) => void;
  value?: string;
  defaultPosition: Position;
  visible: boolean;
  onClose: () => void;
}

const googleLibraries: LoadScriptProps['libraries'] = ['geometry', 'places', 'visualization'];

const googleMapConfig = {
  center: {
    lat: 25.041969,
    lng: 121.544902,
  },
  zoom: 18,
  mapContainerStyle: {
    height: '90%',
    width: 'calc(100% + 32px)',
    position: 'absolute',
    right: 0,
    left: -16,
    top: 0,
  } as CSSProperties,
  options: {
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    clickableIcons: false,
    zoomControl: false,
  },
};

const PullUpStyled = styled(PullUp)`
  height: 90%;
`;

const ControlPanel = styled.div`
  background: white;
  position: absolute;
  bottom: 0;
  height: 255px;
  width: calc(100% + 32px);
  left: -16px;
  padding: 16px;
`;

const InputStyled = styled(Input)`
  margin-bottom: 28px;
`;

const Title = styled.div`
  ${themeGet(`fonts.subtitle`)};
  color: ${themeGet(`colors.black`)};
  margin: 4px 0 20px;
  text-align: center;
`;

const PullUpMapLocate: React.FC<PullUpMapLocateProps> = ({ onChange, visible, onClose, defaultPosition }) => {
  const [mapConfig, setMapConfig] = useState(googleMapConfig);
  const [currentPosition, setCurrentPosition] = useState<Position>(defaultPosition);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey,
    libraries: googleLibraries,
  });
  const [address, setAddress] = useState('');
  const { t } = useTranslation();
  const geocoderRef = useRef<google.maps.Geocoder>();
  const googleMapRef = useRef<google.maps.Map<Element>>();
  const dispatch = useDispatch();

  /*
   *  Android ISSUE: open and close events of keyboard will fire map idle event.
   *  Add this flag to make sure idle event is really from map change event
   */
  const [hasCenterChange, setHasCenterChange] = useState(false);

  const onMapLoad = (ref: google.maps.Map<Element>) => {
    googleMapRef.current = ref;
    googleMapRef.current.set('gestureHandling', 'greedy');
    geocoderRef.current = new google.maps.Geocoder();
  };

  const coordinateToAddress = (position: google.maps.LatLng) => {
    geocoderRef.current?.geocode(
      {
        location: position,
      },
      (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
        if (status === 'OK') {
          if (results[0]) {
            setAddress(results[0].formatted_address.replace(/^\d+/, ''));
          } else {
            setAddress('');
          }
        } else {
          dispatch(
            enqueueNotification({ message: `Geocoder failed due to:${status}`, type: 'warning', duration: 2000 })
          );
        }
      }
    );
  };

  const setNewPosition = () => {
    const center = googleMapRef.current?.getCenter();
    setHasCenterChange(true);
    const lat = center?.lat();
    const lng = center?.lng();
    if (lat && lng && visible) {
      setCurrentPosition({ lat, lng });
    }
  };

  const setNewAddress = () => {
    if (!hasCenterChange) return;
    const center = googleMapRef.current?.getCenter();
    if (center && visible) {
      coordinateToAddress(center);
      setHasCenterChange(false);
    }
  };

  const onConfirm = () => {
    typeof onChange === 'function' && onChange(address);
    onClose();
  };

  const onInputChange = (value: string | undefined) => {
    setAddress(value || '');
  };

  useEffect(() => {
    if (!visible) return;
    setCurrentPosition(defaultPosition);
    if (!isNil(googleMapRef.current)) {
      googleMapRef.current.panTo(defaultPosition || mapConfig.center);
    }
    setMapConfig((preProps) => ({
      ...preProps,
      center: defaultPosition || preProps.center,
    }));
  }, [visible]);

  return (
    <PullUpStyled title={t('biker.confirmPosition')} visible={visible} onClose={onClose} onlyShowsConfirm={true}>
      {isLoaded && (
        <GoogleMap onIdle={setNewAddress} onCenterChanged={setNewPosition} {...mapConfig} onLoad={onMapLoad}>
          <Marker position={currentPosition} icon={IconLocation} />)
        </GoogleMap>
      )}
      <ControlPanel>
        <Title>輸入地址</Title>
        <InputStyled size="large" value={address} onChange={onInputChange} width="100%" clearable={true} />
        <Button label={t('biker.confirm')} onClick={onConfirm} width="100%" />
      </ControlPanel>
    </PullUpStyled>
  );
};

export default PullUpMapLocate;
