import { getProfile as getProfileApi } from "@apis/worker";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isPendingAction, isSucceededAction, isFailedAction } from "./utils";
import { RootState } from "./store";
import { Location } from "~/definition/general";
import { Worker } from "~/definition/worker";

export interface UserState {
  loading: boolean;
  position: Location;
  profile: Worker | null;
}

const initialState: UserState = {
  loading: false,
  position: { lat: 25.041969, lng: 121.544902 },
  profile: null
};

export const getProfile = createAsyncThunk("user/getProfile", async (_, thunkApi) => {
  try {
    const data = await getProfileApi();
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setPosition: (state, action: PayloadAction<{ lng: number; lat: number }>) => {
      state.position = action.payload;
    },
    resetPosition: state => {
      state.position = initialState.position;
    }
  },
  extraReducers: builder => {
    builder.addCase(getProfile.fulfilled, (state, { payload: profile }) => {
      state.profile = profile;
    });
    builder.addMatcher(isPendingAction("user"), state => {
      state.loading = true;
    });
    builder.addMatcher(isSucceededAction("user"), state => {
      state.loading = false;
    });
    builder.addMatcher(isFailedAction("user"), state => {
      state.loading = false;
    });
  }
});

export const { setPosition, resetPosition } = userSlice.actions;

export const selectPosition = (state: RootState): Location => state.user.position;

export const selectUser = (state: RootState): UserState => state.user;

export default userSlice.reducer;
