import { scooterControlActions } from "@configs/.";
import { kottos } from './BikerAxios'

export const controlScooter = (scootetId: string, action: scooterControlActions) => {
  return kottos.get(`/v2/scooters/${scootetId}/scooterControls`, {
    params: {
      action
    }
  });
};
