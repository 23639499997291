import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isPendingAction, isSucceededAction, isFailedAction } from './utils';
import {
  searchScooter as searchScooterApi,
  reserveScooter as reserveScooterApi,
  cancelReserveScooter as cancelReserveScooterApi,
  reportScooterAbnormal,
  getReserveScooter,
} from '@apis/dispatch';
import { RootState } from './store';
import { searchScooterTypes, orderType, scooterGroups } from '@configs/.';
import { Scooter, ScooterPerson } from '~/definition/scooter';

export interface ReportScooterState {
  loading: boolean;
  currentScooter: Scooter | { id: string } | null;
  scooterType: searchScooterTypes | null;
  hasReserved: boolean;
}

const initialState: ReportScooterState = {
  scooterType: null,
  loading: false,
  hasReserved: false,
  currentScooter: null,
};

const getScooterType = (scooter: Scooter, userId: number | null) => {
  let type = searchScooterTypes.unavailable;
  if (scooter.order) {
    if (scooter.order.owners?.find?.(({ id }: ScooterPerson) => id === userId)) {
      type = searchScooterTypes.ownedOrder;
    } else if ([orderType.VAN_COLLECTION, orderType.VAN_DELIVERY].includes(scooter.order.orderType)) {
      type = searchScooterTypes.reservable;
    } else {
      type = searchScooterTypes.othersOrder;
    }
  } else if (scooter.scooterGroupId === scooterGroups.dispatch && !scooter.order) {
    type = searchScooterTypes.reservable;
  } else if (
    scooter.scooterGroupId === scooterGroups.RD ||
    (scooter.scooterGroupId === scooterGroups.normal && !scooter.allBikerItems)
  ) {
    type = searchScooterTypes.reservable;
  } else {
    type = searchScooterTypes.doable;
  }

  return type;
};

export const getCurrentReserved = createAsyncThunk('reportScooter/getCurrentReserved', async (_, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const scooterIds = await getReserveScooter();
    let type = null;

    if (scooterIds?.length > 0) {
      const scooter = await searchScooterApi(scooterIds[0]);
      if (state.user.profile?.id) {
        type = getScooterType(scooter, state.user.profile?.id);
      }
      return { scooter, type };
    }
    return { scooter: null, type };
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const searchScooter = createAsyncThunk('reportScooter/searchScooter', async (id: string, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  try {
    const data = await searchScooterApi(id);
    let type = null;
    if (state.user.profile?.id) {
      type = getScooterType(data, state.user.profile?.id);
    }
    return { scooter: data, type };
  } catch (err) {
    return { scooter: { id }, type: searchScooterTypes.unavailable };
  }
});

export const reserveScooter = createAsyncThunk('reportScooter/reserveScooter', async (_, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    if (state.reportScooter?.currentScooter?.id) {
      const data = await reserveScooterApi(state.reportScooter?.currentScooter?.id);
      return data;
    } else {
      return thunkApi.rejectWithValue(Error('no Scooter'));
    }
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const cancelReserveScooter = createAsyncThunk('reportScooter/cancelReserveScooter', async (_, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    if (state.reportScooter?.currentScooter?.id) {
      const data = await cancelReserveScooterApi(state.reportScooter?.currentScooter?.id);
      return data;
    } else {
      return thunkApi.rejectWithValue(Error('no Scooter'));
    }
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const reportScooterIssue = createAsyncThunk(
  'reportScooter/reportScooterIssue',
  async (formData: { scooterId?: string; photos: []; address: string; reportItems: string[] }, thunkApi) => {
    try {
      const data = await reportScooterAbnormal(formData);
      return data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

const reportScooter = createSlice({
  name: 'reportScooter',
  initialState,
  reducers: {
    setReportScooter: (state, action: PayloadAction<Scooter>) => {
      state.currentScooter = action.payload;
    },
    resetReportScooter: (state) => {
      state.currentScooter = initialState.currentScooter;
      state.scooterType = initialState.scooterType;
      state.hasReserved = initialState.hasReserved;
      state.loading = initialState.loading;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchScooter.fulfilled, (state, { payload }) => {
      state.currentScooter = payload.scooter;
      state.scooterType = payload.type;
    });
    builder.addCase(reserveScooter.fulfilled, (state) => {
      state.hasReserved = true;
    });
    builder.addCase(cancelReserveScooter.fulfilled, (state) => {
      state.currentScooter = null;
      state.hasReserved = false;
      state.scooterType = null;
    });
    builder.addCase(getCurrentReserved.fulfilled, (state, { payload }) => {
      state.currentScooter = payload.scooter;
      state.hasReserved = payload.scooter ? true : false;
    });
    builder.addMatcher(isPendingAction('reportScooter'), (state) => {
      state.loading = true;
    });
    builder.addMatcher(isSucceededAction('reportScooter'), (state) => {
      state.loading = false;
    });
    builder.addMatcher(isFailedAction('reportScooter'), (state) => {
      state.loading = false;
    });
  },
});

export const { setReportScooter, resetReportScooter } = reportScooter.actions;

export const selectReportScooter = (state: RootState): ReportScooterState => state.reportScooter;

export default reportScooter.reducer;
