import React, { useState, ReactNode, ChangeEvent, useEffect } from 'react';
import styled from 'styled-components';
import { BackgroundProps } from 'styled-system';
import { useTranslation } from 'react-i18next';

import Checkbox from './Checkbox';

const CheckboxGroupStyled = styled.div`
  width: 100%;
`;

export interface CheckboxGroupProps {
  defaultValue?: (string | number)[];
  value?: (string | number)[];
  showSelectAll?: boolean;
  onChange?: (value: (string | number)[], lastModify?: { id: string; checked: boolean }) => void;
  options: {
    label: string;
    value: string | number;
    text?: string;
    background?: BackgroundProps['background'];
    onSuffixClick?: (props: { value?: string | number; label: string }) => void;
    suffix?: ReactNode;
  }[];
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
  const { defaultValue, value = [], showSelectAll, onChange, options } = props;
  const { t } = useTranslation();
  const [currentChecked, setCurrentValue] = useState(defaultValue || value);

  const handleOnChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const { value: targetValue, checked: targetChecked } = evt.target;
    const newValue = Array.from(
      new Set([...currentChecked, targetValue].filter((v) => v !== targetValue || (v === targetValue && targetChecked)))
    );
    setCurrentValue(newValue);
    typeof onChange === 'function' && onChange(newValue, { id: targetValue, checked: targetChecked });
  };

  const handleSelectAllOnChange = () => {
    if (typeof onChange === 'function') {
      if (currentChecked.length === 0) {
        onChange(options.map((option) => option.value));
      } else {
        onChange([]);
      }
    }
  };

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(currentChecked)) {
      setCurrentValue(value);
    }
  }, [value, currentChecked]);

  return (
    <CheckboxGroupStyled>
      {showSelectAll && (
        <Checkbox
          label={t(currentChecked.length > 0 ? 'biker.cancelAll' : 'biker.selectAll')}
          value="@@selectAll"
          indeterminate={currentChecked.length > 0 && currentChecked.length < options.length}
          checked={currentChecked.length > 0 && currentChecked.length === options.length}
          onChange={handleSelectAllOnChange}
        />
      )}
      {options.map(({ label, value, text, suffix, background, onSuffixClick }) => (
        <Checkbox
          background={background}
          key={value}
          label={label}
          value={`${value}`}
          onChange={handleOnChange}
          checked={currentChecked.includes(`${value}`)}
          suffix={suffix}
          onSuffixClick={onSuffixClick}
          text={text}
        />
      ))}
    </CheckboxGroupStyled>
  );
};

const defaultProps: Partial<CheckboxGroupProps> = {
  value: [],
  showSelectAll: false,
};
CheckboxGroup.defaultProps = defaultProps;

export default CheckboxGroup;
