import React, { useEffect, useState, useMemo, useCallback, useRef, Fragment } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import CardOrderItem from '@organisms/CardOrderItem';
import Count from '@atoms/Count';
import Loading from '@atoms/Loading';
import {
  selectPoolOrders,
  selectPoolOrderLoading,
  setCurrentOrder,
  setMapPosition,
  selectMapPosition,
  setFilter,
} from '@redux/poolOrders';
import { selectReportScooter, getCurrentReserved } from '@redux/reportScooter';
import { AppDispatch } from '@redux/store';
import usePoolOrder from '@hooks/usePoolOrders';
import useSearchScooter from '@hooks/useSearchScooter';
import InputSearch, { ScooterIdPattern } from '@organisms/InputSearch';
import { getSearchableItems, selectSearchableItems } from '@redux/reportableItems';
import { getScooterTypeComponent } from '@utils/getScooterTypeComponent';
import CardReportReserved from '@organisms/CardReportReserved';
import { RouteComponentProps } from 'react-router';
import { Order } from '~/definition/order';
import { Scooter } from '~/definition/scooter';
import { selectPosition } from '@redux/user';

export interface BikerPoolProps extends RouteComponentProps {
  headerPortal?: HTMLDivElement;
}

const Container = styled.div`
  padding: 80px 0;
`;

const CardContainer = styled.div`
  padding: 8px 0;
`;

const CountContainer = styled.div`
  background-color: white;
  float: left;
`;

const InputSearchStyled = styled(InputSearch)`
  background: white;
  padding: 0 16px;
`;

const Empty = () => <Fragment />;

const BikerPool: React.FC<BikerPoolProps> = ({ history, headerPortal }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { poolOrders, poolCount, filter } = useSelector(selectPoolOrders);
  const loading = useSelector(selectPoolOrderLoading);
  const { acceptThisOrder } = usePoolOrder();
  const [onFilterSet, setOnFilterSet] = useState(false);
  const searchableItems = useSelector(selectSearchableItems);
  const reportScooter = useSelector(selectReportScooter);
  const position = useSelector(selectPosition);
  const mapPosition = useSelector(selectMapPosition);
  const searchRef = useRef<{ resetFields: () => void }>(null);
  const { onSearch, onReserve, onCancelReserve } = useSearchScooter({
    searchRef,
  });

  const onReportClick = () => {
    history.push(`/scooter/${reportScooter?.currentScooter?.id}/abnormal-report`);
  };

  const onViewOwnedOrder = useCallback(() => {
    if ((reportScooter?.currentScooter as Scooter)?.order?.id) {
      history.push(`/dispatch/${(reportScooter?.currentScooter as Scooter)?.order?.id}/form`);
    }
  }, [(reportScooter?.currentScooter as Scooter)?.order?.id]);

  const SearchScooterComp = useMemo(
    () =>
      reportScooter.scooterType && reportScooter.currentScooter?.id
        ? getScooterTypeComponent({
            type: reportScooter.scooterType,
            isMapMode: false,
            scooterId: reportScooter.currentScooter?.id,
            onReserveClick: onReserve,
            onViewOrder: onViewOwnedOrder,
          })
        : Empty,
    [reportScooter, onReserve, onViewOwnedOrder]
  );

  useEffect(() => {
    if (!mapPosition) {
      dispatch(setMapPosition(position));
    }
    dispatch(getCurrentReserved());
  }, []);

  useEffect(() => {
    if (!searchableItems) {
      dispatch(getSearchableItems());
    }
  }, []);

  const viewOrder = useCallback((order: Order) => {
    history.push(`/dispatch/${order.id}/view`);
    dispatch(setCurrentOrder(order));
  }, []);

  const onFilterChange = (filter: { scooterId: string; includeTaskItems: string[] }) => {
    setOnFilterSet(true);
    dispatch(setFilter(filter));
  };

  useEffect(() => {
    if (mapPosition && onFilterSet) {
      onSearch();
    }
  }, [filter, mapPosition]);

  return (
    <Container>
      {headerPortal &&
        ReactDOM.createPortal(
          <Fragment>
            <InputSearchStyled
              ref={searchRef}
              defaultFilter={filter}
              onFilterChange={onFilterChange}
              items={searchableItems}
              showSelectAll
            />
            <CountContainer>
              <Count count={poolCount} type="pool" />
            </CountContainer>
          </Fragment>,
          headerPortal
        )}
      <CardContainer>
        {poolOrders.length === 0 && !reportScooter.hasReserved && <SearchScooterComp />}
        {reportScooter.hasReserved && reportScooter.currentScooter?.id && (
          <CardReportReserved
            scooterId={reportScooter.currentScooter?.id}
            isMapMode={false}
            onCancelReserve={onCancelReserve}
            onReportClick={onReportClick}
          />
        )}
        {poolOrders.map((order) => (
          <CardOrderItem
            onClick={viewOrder}
            key={order.id}
            orderInfo={order}
            isAddMode={true}
            onAddClick={acceptThisOrder}
          />
        ))}
      </CardContainer>
      <Loading visible={loading || reportScooter.loading} />
    </Container>
  );
};

export default BikerPool;
