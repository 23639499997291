import React from "react";
import styled from "styled-components";
import ItemText from "@atoms/ItemText";
import { ReactComponent as IconUpward } from "@assets/iconUpward.svg";
import { DispatchLocation } from "~/definition/order";

const LinkStyle = styled.a`
  text-decoration: none;
`;

const ItemTextOrigin: React.FC<DispatchLocation> = ({
  name,
  address,
  locationLat,
  locationLng,
  isOffice,
}) => {
  return (
    <LinkStyle
      href={`http://maps.google.com/maps?daddr=${locationLat},${locationLng}`}
      target="_blank"
    >
      <ItemText
        Icon={IconUpward}
        title={isOffice ? name : address}
        text={isOffice ? address : null}
      />
    </LinkStyle>
  );
};

export default ItemTextOrigin;
