import { BikerOrdersInfo, Order, PoolOrderRequest } from '~/definition/order';
import { Scooter, ScooterInfo } from '~/definition/scooter';
import { ReportItem } from '~/definition/general';
import { kottos } from './BikerAxios';
import { generateFormData } from '@utils/generateFormData';

export const getOrders = () => {
  return kottos.get<{ data: BikerOrdersInfo }>('/talos/v1/dispatch/getOrders').then(({ data }) => data.data);
};

export const getOrderById = (id: string) => {
  return kottos.get<{ data: Order }>(`/talos/v1/dispatch/getOrder/${id}`).then(({ data }) => data.data);
};

export const getScooterInfoById = (scooterId: string) => {
  return kottos
    .get<{ data: ScooterInfo }>('/talos/v1/dispatch/scooter/' + scooterId + '/info')
    .then(({ data }) => data.data);
};

export const getBikerPool = (filter?: PoolOrderRequest) => {
  return kottos
    .get<{ data: Order[] }>('/talos/v1/dispatch/searchOrderPool', { params: filter })
    .then(({ data }) => data.data);
};

export const getReportableItems = (type: number) => {
  return kottos
    .get<{ data: ReportItem[] }>('/talos/v1/dispatch/getReportables', {
      params: {
        type,
      },
    })
    .then(({ data }) => data.data);
};

export const confirmOrdersByIds = (orderIds: string[]) => {
  return kottos.patch('/talos/v1/dispatch/confirmOrders', { orderIds });
};

export const addPoolOrdersByIds = (orderIds: string[]) => {
  return kottos.patch('/talos/v1/dispatch/addMyOrder', { orderIds });
};

export const removeOrdersByIds = (orderIds: string[]) => {
  return kottos.patch('/talos/v1/dispatch/removeMyOrder', { orderIds });
};

export const completeOrder = (formData: {
  orderId: string;
  completedTaskIds: string[];
  address: string;
  comment: string;
  photos: string[];
}) => {
  return kottos.post('/talos/v1/dispatch/completeOrder', generateFormData(formData), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const reportAbnormal = (formData: { orderId?: string; photos: []; address: string; reportItems: string[] }) => {
  return kottos
    .post<{ data: { bikerDoable: boolean; orderId: string } }>(
      '/talos/v1/dispatch/reportAbnormal',
      generateFormData(formData),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then(({ data }) => data.data);
};

export const reportScooterAbnormal = (formData: {
  scooterIdId?: string;
  photos: [];
  address: string;
  reportItems: string[];
}) => {
  return kottos
    .post<{ data: { bikerDoable: boolean; orderId: string } }>(
      '/talos/v1/dispatch/reportViolation',
      generateFormData(formData),
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then(({ data }) => data.data);
};

export const searchScooter = (scooterId: string) => {
  return kottos
    .get<{ data: Scooter }>('/talos/v1/dispatch/searchScooters', { params: { scooterId } })
    .then(({ data }) => data.data);
};

export const getReserveScooter = () => {
  return kottos.get<{ data: string[] }>(`/talos/v1/dispatch/scooter/reserve`).then(({ data }) => data.data);
};

export const reserveScooter = (scooterId: string) => {
  return kottos.post(`/talos/v1/dispatch/scooter/${scooterId}/reserve`);
};

export const cancelReserveScooter = (scooterId: string) => {
  return kottos.delete(`/talos/v1/dispatch/scooter/${scooterId}/reserve`);
};
