import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTransition } from 'react-spring';
import { animated } from 'react-spring';
import { color, ColorProps, padding, PaddingProps } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

const Container = styled.div<PaddingProps>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 2;
  text-align: center;
  /* padding: 12px; */
  z-index: 4;
  ${padding};
`;

const SnackbarItem = styled(animated.div).withConfig({
  shouldForwardProp: (props, defaultValidatorFn) => !['backgroundColor'].includes(props) && defaultValidatorFn(props),
})<ColorProps>`
  // NOTE: Can't use 'backgroundColor': Attempted import error: 'backgroundColor' is not exported from 'styled-system'.
  ${color}
  ${themeGet('fonts.body2')};
  color: white;
  display: inline-block;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  padding: 8px;
`;

const Message = styled.span`
  line-height: 27px;
  display: inline-block;
`;

const Suffix = styled.span`
  line-height: 27px;
  float: right;
  display: inline-block;
`;

const transitionConfig = { tension: 125, friction: 20, precision: 0.1 };

export interface SnackbarProps {
  visible: boolean;
  onEnter?: () => void;
  onClose: () => void;
  message: string;
  duration?: number;
  suffix?: string;
  onSuffixClick?: () => void;
  type?: 'warning' | 'normal';
}

export const Snackbar: React.FC<SnackbarProps> = ({
  visible,
  suffix,
  onSuffixClick,
  onClose,
  message,
  duration = 2000,
  type = 'normal',
}) => {
  const isMounted = useRef(false);
  const snackbarTransition = useTransition(visible, null, {
    from: { opacity: 0, transform: `translate3d(0,-100px,0)` },
    enter: { opacity: 1, transform: `translate3d(0,0,0)` },
    leave: { opacity: 0, transform: `translate3d(0,-100px,0)` },
    config: transitionConfig,
  });

  useEffect(() => {
    if (visible && duration) {
      const id = setTimeout(() => {
        isMounted.current && onClose();
      }, duration);
      return () => {
        clearTimeout(id);
      };
    }
  }, [visible, duration]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleSuffixClick = () => {
    typeof onSuffixClick === 'function' && onSuffixClick();
  };

  return (
    <Container padding={visible ? '12px' : '0px'}>
      {snackbarTransition.map(
        ({ item, key, props }) =>
          item && (
            <SnackbarItem key={key} style={props} backgroundColor={type === 'normal' ? 'blue400' : 'error'}>
              <Message>{message}</Message>
              <Suffix onClick={handleSuffixClick}>{suffix}</Suffix>
            </SnackbarItem>
          )
      )}
    </Container>
  );
};

export default Snackbar;
