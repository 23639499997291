import { useDispatch } from "react-redux";
import { addOrder, removeLastOrder } from "@redux/poolOrders";
import { AppDispatch } from "@redux/store";
import { dequeueNotification, enqueueNotification } from "@redux/notification";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { getOrders } from "@redux/ownedOrders";

const usePoolOrder = (): {
  acceptThisOrder: (id: string, redirectPath?: string) => void;
  undoAddOrder: () => void;
} => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const history = useHistory();

  const acceptThisOrder = async (id: string, redirectPath?: string) => {
    const action = await dispatch(addOrder(id));
    if (addOrder.fulfilled.match(action)) {
      dispatch(
        enqueueNotification({
          message: t("biker.addSucceeded"),
          duration: 4000,
          suffix: "Undo",
          onSuffixClick: undoAddOrder
        })
      );
      if (redirectPath) {
        history.replace(redirectPath);
      }
    } else if (addOrder.rejected.match(action)) {
      let message = '';

      if (action.payload?.isAxiosError && action.payload.response?.status === 490) {
        message = t('biker.occupiedScooter')
      } else {
        message = t("biker.addFailed")
      }

      dispatch(
        enqueueNotification({
          message,
          duration: 2000,
          type: "warning"
        })
      );
    }
  };

  const undoAddOrder = async () => {
    const action = await dispatch(removeLastOrder());
    if (removeLastOrder.fulfilled.match(action)) {
      dispatch(dequeueNotification());
      dispatch(
        enqueueNotification({
          message: t("biker.reAddedSucceeded"),
          duration: 2000
        })
      );
      dispatch(getOrders());
    } else if (removeLastOrder.rejected.match(action)) {
      dispatch(
        enqueueNotification({
          message: t("biker.reAddedFailed"),
          duration: 2000,
          type: "warning"
        })
      );
    }
  };

  return { acceptThisOrder, undoAddOrder };
};

export default usePoolOrder;
