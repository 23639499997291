import React, { useEffect, useCallback, useState, useMemo, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import {
  getOrders,
  resetCurrentOrder,
  selectMapPosition,
  selectPoolOrders,
  setCurrentOrder,
  setFilter,
  setMapPosition,
} from '@redux/poolOrders';
import { getSearchableItems, selectSearchableItems } from '@redux/reportableItems';
import { resetReportScooter, selectReportScooter } from '@redux/reportScooter';
import { selectPosition } from '@redux/user';
import IconTask from '@assets/iconTask.svg';
import Button from '@atoms/Button';
import Loading from '@atoms/Loading';
import MapInfo, { PinData } from '@molecules/MapInfo';
import InputSearch, { ScooterIdPattern } from '@organisms/InputSearch';
import CardOrderMap from '@organisms/CardOrderMap';
import CardReportReserved from '@organisms/CardReportReserved';
import useSearchScooter from '@hooks/useSearchScooter';
import usePoolOrder from '@hooks/usePoolOrders';
import { getScooterTypeComponent } from '@utils/getScooterTypeComponent';
import { Scooter } from '~/definition/scooter';
import { Location } from '~/definition/general';
import { searchScooter } from '@apis/dispatch';

const CardOrderMapStyled = styled(CardOrderMap)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 16px);
  margin: 8px;
`;

const InputSearchStyled = styled(InputSearch)`
  background: white;
  padding: 0 16px 12px;
  position: absolute;
  top: 43px;
  width: 100%;
`;

const ButtonToggle = styled(Button)`
  position: absolute;
  right: 8px;
  top: 105px;
  z-index: 2;
`;

const Empty = () => null;

export interface PoolMapProps {
  history: RouteComponentProps['history'];
  headerPortal?: HTMLDivElement;
}

const PoolMap: React.FC<PoolMapProps> = ({ history, headerPortal }) => {
  const { poolOrders, currentOrder, filter } = useSelector(selectPoolOrders);
  const position = useSelector(selectPosition);
  const mapPosition = useSelector(selectMapPosition);
  const dispatch = useDispatch();
  const { acceptThisOrder } = usePoolOrder();
  const [onFilterSet, setOnFilterSet] = useState(false);
  const searchableItems = useSelector(selectSearchableItems);

  const searchRef = useRef<{ resetFields: () => void }>(null);
  const { onSearch, onReserve, onCancelReserve } = useSearchScooter({
    searchRef,
  });

  const onFilterChange = (filter: { scooterId: string; includeTaskItems: string[] }) => {
    dispatch(setFilter(filter));
    setOnFilterSet(true);
  };
  const reportScooter = useSelector(selectReportScooter);

  const onReportClick = () => {
    history.push(`/scooter/${reportScooter?.currentScooter?.id}/abnormal-report`);
  };

  const onViewOrder = useCallback(() => {
    const currentScooter = reportScooter.currentScooter as Scooter;
    if (currentScooter && currentScooter.order?.id) {
      history.push(`/dispatch/${currentScooter.order?.id}/form`);
    }
  }, [reportScooter.currentScooter, history]);

  const SearchScooterComp = useMemo(
    () =>
      reportScooter.scooterType && reportScooter?.currentScooter?.id
        ? getScooterTypeComponent({
            type: reportScooter.scooterType,
            isMapMode: true,
            scooterId: reportScooter.currentScooter.id,
            onReserveClick: onReserve,
            onViewOrder,
          })
        : Empty,
    [reportScooter, onReserve, onViewOrder]
  );

  const onPinClick = (order: PinData) => {
    dispatch(setCurrentOrder(order));
  };

  const handleOnMapClick = () => {
    dispatch(resetCurrentOrder());
    if (reportScooter.currentScooter && !reportScooter.hasReserved) {
      dispatch(resetReportScooter());
      dispatch(getOrders());
      searchRef.current?.resetFields();
    }
  };

  const reportOrder = () => {
    if (currentOrder?.id) {
      history.push(`/dispatch/${currentOrder.id}/abnormal-report`);
    }
  };

  const reportMissingScooter = () => {
    if (currentOrder?.id) {
      history.push(`/dispatch/${currentOrder.id}/missing-scooter-report`);
    }
  };

  const viewOrder = () => {
    if (currentOrder?.id) {
      history.push(`/dispatch/${currentOrder.id}/view`);
    }
  };

  useEffect(() => {
    return () => {
      if (!reportScooter.hasReserved) {
        dispatch(resetReportScooter());
      }
    };
  }, []);

  const handleOnMapChange = (position: Location) => {
    if (!mapPosition || position.lng !== mapPosition.lng || position.lat !== mapPosition.lat) {
      dispatch(setMapPosition(position));
    }
  };

  useEffect(() => {
    if (!searchableItems) {
      dispatch(getSearchableItems());
    }
  }, []);

  useEffect(() => {
    if (mapPosition && onFilterSet) {
      onSearch();
    }
  }, [filter, mapPosition]);

  return (
    <Fragment>
      <MapInfo
        onMapIdle={handleOnMapChange}
        onMapClick={handleOnMapClick}
        onPinClick={onPinClick}
        poolList={poolOrders}
        currentOrder={currentOrder as PinData | null}
        currentScooter={reportScooter.currentScooter as Scooter}
        currentUserPosition={position}
        currentCenterPosition={mapPosition || position}
        hasCenterCircle
      />
      <ButtonToggle
        shape="circle"
        Icon={IconTask}
        size="small"
        color="plan"
        onClick={() => history.replace('/dispatch-map')}
      />
      {headerPortal &&
        ReactDOM.createPortal(
          <InputSearchStyled
            ref={searchRef}
            defaultFilter={filter}
            onFilterChange={onFilterChange}
            items={searchableItems}
            showSelectAll
          />,
          headerPortal
        )}
      {poolOrders.length === 0 && !reportScooter.hasReserved && <SearchScooterComp />}
      {reportScooter.hasReserved && reportScooter.currentScooter?.id && (
        <CardReportReserved
          scooterId={reportScooter.currentScooter?.id}
          isMapMode={true}
          onCancelReserve={onCancelReserve}
          onReportClick={onReportClick}
        />
      )}
      {currentOrder && (
        <CardOrderMapStyled
          orderInfo={currentOrder}
          isAddMode={true}
          onReportClick={reportOrder}
          onAcceptOrder={acceptThisOrder}
          onViewPoolOrder={viewOrder}
          onMissingReportClick={reportMissingScooter}
        />
      )}
      <Loading visible={reportScooter.loading} />
    </Fragment>
  );
};

export default PoolMap;
