import React, { ReactNode, useRef, useState, useEffect, Fragment } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { useTransition, animated } from "react-spring/web";
import { themeGet } from "@styled-system/theme-get";
import { useTranslation } from "react-i18next";

export interface PurePullUpProps {
  children: ReactNode;
  title: string;
  visible: boolean;
  onlyShowsConfirm?: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  className?: string;
}

const PullUpContainer = styled(animated.div)`
  background-color: white;
  position: fixed;
  width: 100%;
  height: 90%;
  left: 0;
  bottom: 0;
  border-radius: 15px 15px 0 0;
  padding: 16px;
  z-index: 4;
`;

const ControlPanel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
  ${themeGet(`fonts.subtitle`)};
  color: ${themeGet(`colors.black`)};
`;

const Cancel = styled.span`
  flex: 1;
`;

const Title = styled.span`
  flex: 2;
  text-align: center;
`;

const Confirm = styled.span`
  flex: 1;
  text-align: right;
`;

const Content = styled.div`
  text-align: left;
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  ${themeGet(`fonts.subtitle`)};
  color: ${themeGet(`colors.black`)};
  padding-bottom: 30px;
`;

const Mask = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.75);
  user-select: none;
  z-index: 3;
`;

const PurePullUp: React.FC<PurePullUpProps> = ({
  children,
  title,
  className,
  visible = false,
  onlyShowsConfirm = false,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation();
  const [originalRootStyle, setOriginalRootStyle] = useState({
    overflow: "",
    position: "",
    scrollY: 0
  });
  const root = useRef<HTMLElement | null>(null);
  const transitions = useTransition(visible, null, {
    from: { transform: `translate3d(0,50%,0)`, opacity: 0 },
    enter: { transform: `translate3d(0,0%,0)`, opacity: 1 },
    leave: { transform: `translate3d(0,50%,0)`, opacity: 0 }
  });

  useEffect(() => {
    if (root.current) {
      if (visible) {
        setOriginalRootStyle({
          ...originalRootStyle,
          scrollY: window.scrollY
        });
        root.current.style.overflow = "hidden";
        root.current.style.position = "fixed";
      } else {
        root.current.style.overflow = originalRootStyle.overflow;
        root.current.style.position = originalRootStyle.position;
        window.scrollTo(0, originalRootStyle.scrollY);
      }
    }
  }, [visible]);

  useEffect(() => {
    root.current = document.getElementById("root");

    if (root.current) {
      setOriginalRootStyle({
        overflow: root.current.style.overflow,
        position: root.current.style.position,
        scrollY: 0
      });
    }
    return () => {
      if (root.current) {
        root.current.style.overflow = originalRootStyle.overflow;
        root.current.style.position = originalRootStyle.position;
        window.scrollTo(0, originalRootStyle.scrollY);
      }
    };
  }, []);

  return (
    <Fragment>
      {visible && <Mask onClick={onClose} />}
      <Fragment>
        {transitions.map(({ item, props, key }) =>
          item ? (
            <Fragment key={key}>
              <PullUpContainer key={key} className={className} style={props}>
                <ControlPanel>
                  <Cancel onClick={onClose}>{t("biker.close")}</Cancel>
                  <Title>{title}</Title>
                  {!onlyShowsConfirm ? <Confirm onClick={onConfirm}>{t("biker.confirm")}</Confirm> : <Confirm />}
                </ControlPanel>
                <Content>{children}</Content>
              </PullUpContainer>
            </Fragment>
          ) : null
        )}
      </Fragment>
    </Fragment>
  );
};

const PortalPullUp: React.FC<PurePullUpProps> = ({ ...props }) => {
  return ReactDOM.createPortal(<PurePullUp {...props} />, document.body);
};

export default PortalPullUp;
