import React, { Fragment, useState } from "react";
import styled from "styled-components";
import Card from "@atoms/Card";
import Button from "@atoms/Button";
import ScooterInfo from "@organisms/ScooterInfo";
import PullUpAbnormal from "@molecules/PullUpAbnormal";
import { useTranslation } from "react-i18next";
import useFadeIn from "@hooks/useFadeIn";

export interface CardReportReservedProps {
  scooterId: string;
  className?: string;
  isMapMode: boolean;
  onReportClick?: () => void;
  onCancelReserve?: () => void;
}

const CardStyled = styled(Card)<{ className?: string; hasRadius: boolean; shouldFixed: boolean }>`
  border-radius: ${({ hasRadius }) => (hasRadius ? 5 : 0)}px;
  position: ${({ shouldFixed }) => (shouldFixed ? "fixed" : "relative")};
  bottom: ${({ shouldFixed }) => (shouldFixed ? 0 : "auto")};
  width: calc(100% - 16px);
  margin: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const ScooterInfoStyled = styled(ScooterInfo)`
  margin: 4px 0;
`;

const Text = styled.div`
  ${props => props.theme.fonts.subtitle};
  color: ${props => props.theme.colors.black};
`;

const CardReportReserved: React.FC<CardReportReservedProps> = ({
  scooterId,
  isMapMode,
  onCancelReserve,
  onReportClick,
  className
}) => {
  const [isReportVisible, setIsReportVisible] = useState(false);
  const { t } = useTranslation();
  const { transitions } = useFadeIn(isMapMode);

  const onAbnormalReportClick = () => {
    setIsReportVisible(true);
  };

  const handleReportClick = () => {
    typeof onReportClick === "function" && onReportClick();
  };

  const handleCancelReserve = () => {
    typeof onCancelReserve === "function" && onCancelReserve();
  };

  return (
    <Fragment>
      {transitions.map(({ props, key }) => (
        <CardStyled className={className} padding={12} hasRadius={isMapMode} shouldFixed={isMapMode} key={key} style={props}>
          <ScooterInfoStyled scooterId={scooterId} />
          <Text>{t("biker.hasReserveScooter")}</Text>
          <ButtonContainer>
            <Fragment>
              <Button
                shape="round"
                label={t("biker.cancelReserve")}
                color="plan"
                size="small"
                width="40%"
                onClick={handleCancelReserve}
              />
              <Button
                shape="round"
                label={t("biker.report")}
                color="primary"
                size="small"
                width="40%"
                onClick={onAbnormalReportClick}
              />
            </Fragment>
          </ButtonContainer>
          <PullUpAbnormal
            visible={isReportVisible}
            onClose={() => setIsReportVisible(false)}
            onReportClick={handleReportClick}
            hasMissingReport={false}
          />
        </CardStyled>
      ))}
    </Fragment>
  );
};

export default CardReportReserved;
