import React, { useState, useMemo, Fragment } from 'react';
import { themeGet } from '@styled-system/theme-get';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { justifyContent, JustifyContentProps } from 'styled-system';
import moment from 'moment';
import isNil from 'lodash.isnil';
import Tag from '@atoms/Tag';
import Card from '@atoms/Card';
import Popup from '@atoms/Popup';
import Button from '@atoms/Button';
import ItemText from '@atoms/ItemText';
import ItemTextOrigin from '@molecules/ItemTextOrigin';
import ScooterInfo from '@organisms/ScooterInfo';
import { ReactComponent as IconText } from '@assets/iconText.svg';
import { ReactComponent as IconDot } from '@assets/iconDot.svg';
import { dispatchTaskPriority } from '@configs/.';
import { Order } from '~/definition/order';

export interface CardOrderItemProps {
  isAddMode: boolean;
  isRemoveMode?: boolean;
  onClick?: (order: Order) => void;
  onAddClick?: (id: string) => void;
  onRemoveClick?: (id: string) => void;
  orderInfo: Order;
}

const Date = styled.div`
  background-color: white;
  ${themeGet('fonts.body2')};
  color: ${themeGet('colors.secVariant')};
`;

const Flex = styled.div<JustifyContentProps>`
  display: flex;
  ${justifyContent}
`;

const Row = styled.div`
  border-bottom: 1px solid ${themeGet('colors.secondary')};
  padding: 8px 16px;
`;

const CardOrderItem: React.FC<CardOrderItemProps> = ({
  isAddMode,
  isRemoveMode,
  onClick,
  onAddClick,
  onRemoveClick,
  orderInfo,
}) => {
  const { scooterId, origin, managerComment, assignedAt, createdAt, priority, tasks } = orderInfo || {};
  const { t, i18n } = useTranslation();
  const locale = useMemo(() => i18n.language.toLocaleLowerCase(), [i18n.language]);
  const [isCommentVisible, setIsCommentVisible] = useState(false);

  const handleOnAddClick = () => {
    typeof onAddClick === 'function' && onAddClick(orderInfo.id);
  };

  const handleOnRemoveClick = () => {
    typeof onRemoveClick === 'function' && onRemoveClick(orderInfo.id);
  };

  const handleOnClick = () => {
    typeof onClick === 'function' && onClick(orderInfo);
  };

  return (
    <Card>
      <Row>
        <Flex justifyContent="space-between">
          <ScooterInfo
            scooterId={scooterId}
            hasRedDot={orderInfo.taskModifiedAt ? true : false}
            hasForbidden={orderInfo.scooterHasNonBikerItem}
          />
          {isAddMode ? (
            <Button
              width="auto"
              label={t('biker.add')}
              shape="capsule"
              size="small"
              color="plan"
              onClick={handleOnAddClick}
            />
          ) : isRemoveMode ? (
            <Button
              width="auto"
              label={t('biker.remove')}
              shape="capsule"
              size="small"
              color="plan"
              onClick={handleOnRemoveClick}
            />
          ) : (
            priority && (
              <Tag
                text={dispatchTaskPriority[`${priority}`][locale]}
                color={dispatchTaskPriority[`${priority}`]?.color}
              />
            )
          )}
        </Flex>
        <Date>
          {isAddMode
            ? moment(createdAt).format('YYYY-MM-DD HH:mm:ss')
            : moment(assignedAt).format('YYYY-MM-DD HH:mm:ss')}
        </Date>
      </Row>
      <div onClick={handleOnClick}>
        <Row>{origin && <ItemTextOrigin {...origin} />}</Row>
        <Row>
          {tasks?.map(({ id, taskId, maintenanceItem, name, taskName, description }) => (
            <ItemText
              key={isNil(id) ? `taskId_${taskId}` : `id_${id}`}
              Icon={IconDot}
              title={`${name || taskName}`}
              subtitle={maintenanceItem?.id ? `Item ID ${maintenanceItem?.id}` : ''}
              text={description || maintenanceItem?.description}
            />
          ))}
        </Row>
      </div>
      {managerComment && (
        <Fragment>
          <Row>
            <ItemText Icon={IconText} text={managerComment} onClick={() => setIsCommentVisible(true)} />
          </Row>
          <Popup
            title={t('biker.managerComment') + `(${scooterId})`}
            visible={isCommentVisible}
            onlyShowsConfirm={true}
            onClose={() => setIsCommentVisible(false)}
          >
            {managerComment}
          </Popup>
        </Fragment>
      )}
    </Card>
  );
};

export default CardOrderItem;
