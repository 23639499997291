import React, { ReactNode, useRef, useState, Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { useTransition, animated, config } from "react-spring";
import Button from "@atoms/Button";
import { themeGet } from "@styled-system/theme-get";
import { useTranslation } from "react-i18next";

export interface PurePopupProps {
  children?: ReactNode;
  title?: string;
  visible: boolean;
  onlyShowsConfirm?: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  hideButtons?: boolean;
}

const PopupContainer = styled(animated.div)`
  background-color: white;
  position: fixed;
  width: 95%;
  min-height: 40%;
  height: auto;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  margin: auto;
  border-radius: 15px;
  padding: 16px;
  display: flex;
  flex-flow: wrap;
  align-content: space-between;
  z-index: 4;
`;

const ControlPanel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${themeGet(`fonts.subtitle`)};
  color: ${themeGet(`colors.black`)};
`;

const CancelButton = styled(Button)``;

const Title = styled.span`
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
`;

const Content = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 12px;
  overflow: scroll;
  ${themeGet(`fonts.body2`)};
  color: ${themeGet(`colors.black`)};
`;

const CloseButton = styled(Button)`
  margin: 0 auto;
`;

const Mask = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.75);
  user-select: none;
  z-index: 3;
`;

const PurePopup: React.FC<PurePopupProps> = ({
  children,
  hideButtons = false,
  onlyShowsConfirm = false,
  title,
  visible = false,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const [originalRootStyle, setOriginalRootStyle] = useState({
    overflow: "",
    position: "",
    scrollY: 0,
  });
  const root = useRef<HTMLElement | null>(null);
  const transitions = useTransition(visible, null, {
    from: { transform: `translate3d(0,-30%,0)`, opacity: 0 },
    enter: { transform: `translate3d(0,-50%,0)`, opacity: 1 },
    leave: { transform: `translate3d(0,-30%,0)`, opacity: 0 },
    config: config.gentle,
  });

  useEffect(() => {
    if (root.current) {
      if (visible) {
        setOriginalRootStyle({
          ...originalRootStyle,
          scrollY: window.scrollY,
        });
        root.current.style.overflow = "hidden";
        root.current.style.position = "fixed";
      } else {
        root.current.style.overflow = originalRootStyle.overflow;
        root.current.style.position = originalRootStyle.position;
        window.scrollTo(0, originalRootStyle.scrollY);
      }
    }
  }, [visible]);

  useEffect(() => {
    root.current = document.getElementById("root");

    if (root.current) {
      setOriginalRootStyle({
        overflow: root.current.style.overflow,
        position: root.current.style.position,
        scrollY: 0,
      });
    }
    return () => {
      if (root.current) {
        root.current.style.overflow = originalRootStyle.overflow;
        root.current.style.position = originalRootStyle.position;
        window.scrollTo(0, originalRootStyle.scrollY);
      }
    };
  }, []);

  return (
    <Fragment>
      {visible && <Mask onClick={onClose} />}
      {transitions.map(({ item, props, key }) =>
        item ? (
          <Fragment key={key}>
            <PopupContainer style={props}>
              <Title>{title}</Title>
              <Content>{children}</Content>
              {!hideButtons && (
                <ControlPanel>
                  {onlyShowsConfirm ? (
                    <CloseButton
                      onClick={onClose}
                      label={t("biker.close")}
                      color="plan"
                      width="40%"
                      size="small"
                    />
                  ) : (
                    <Fragment>
                      <CancelButton
                        onClick={onClose}
                        label={t("biker.cancel")}
                        color="plan"
                        width="40%"
                        size="small"
                      />
                      <Button
                        onClick={onConfirm}
                        label={t("biker.confirm")}
                        color="primary"
                        width="40%"
                        size="small"
                      />
                    </Fragment>
                  )}
                </ControlPanel>
              )}
            </PopupContainer>
          </Fragment>
        ) : null
      )}
    </Fragment>
  );
};

const PortalPopup: React.FC<PurePopupProps> = ({ ...props }) => {
  return ReactDOM.createPortal(<PurePopup {...props} />, document.body);
};

export default PortalPopup;
