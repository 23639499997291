import React, { useState, useRef, useMemo } from 'react';
import Form, { Field, useForm } from 'rc-field-form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import isNil from 'lodash.isnil';

import CardPhotoEditor from '@organisms/CardPhotoEditor';
import PullUpMapLocate from '@molecules/PullUpMapLocate';
import Button from '@atoms/Button';
import Card from '@atoms/Card';
import PopupInputForm from '@molecules/PopupInputForm';
import { CheckboxGroup } from '@atoms/Checkbox';
import { Location, ReportItem } from '~/definition/general';

export interface ReportFormProps {
  items: ReportItem[];
  position: Location;
  id?: string;
  onReport: (data: { id?: string; photos: []; address: string; reportItems: string[] }) => void;
  loading: boolean;
}

const Container = styled.div`
  padding-top: 4px;
  padding-bottom: 80px;
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  background: white;
  padding: 12px;
  width: 100%;
`;

const ReportForm: React.FC<ReportFormProps> = ({ items, loading, position, onReport, id }) => {
  const [form] = useForm();
  const [isInputTextVisible, setIsInputTextVisible] = useState(false);
  const [isLocaterVisible, setIsLocaterVisible] = useState(false);
  const [text, setText] = useState('');
  const [, forceUpdate] = useState({});
  const { t } = useTranslation();
  const commentIdRef = useRef<string | number>();

  useMemo(() => {
    items?.forEach(({ id, commentable }) => {
      if (commentable) {
        commentIdRef.current = id;
      }
    });
  }, [items]);

  const submitForm = async () => {
    setIsLocaterVisible(true);
  };

  const onAddressChange = (address: string) => {
    const { photos, reportItem } = form.getFieldsValue();
    const newReportItems = reportItem?.map((id: string) =>
      id === commentIdRef.current ? `${id}:${encodeURIComponent(text)}` : id
    );
    onReport({ id, photos, reportItems: newReportItems, address });
  };

  const onMapClose = () => {
    setIsLocaterVisible(false);
  };

  const onCheck = (value: (string | number)[], lastModify?: { id: string; checked: boolean }) => {
    const hasComment = lastModify?.id ? commentIdRef.current === lastModify?.id : false;
    if (hasComment && lastModify?.checked) {
      setIsInputTextVisible(true);
    } else if (hasComment && !lastModify?.checked) {
      setText('');
    }
    // NOTE: if check a checkbox without comment, it will not trigger rerender, so the submit button will remain disabled
    forceUpdate({});
  };

  const handleOnConfirm = (value: string) => {
    setText(value || '');
  };

  const onCommentSuffixClick = ({ value }: { value?: string | number | undefined }) => {
    if (value) {
      setIsInputTextVisible(true);
      const reportItemValue = form.getFieldValue('reportItem');
      if (!isNil(reportItemValue) && !reportItemValue.includes(value)) {
        form.setFieldsValue({ reportItem: [...reportItemValue, value] });
      } else {
        form.setFieldsValue({ reportItem: [value] });
      }
    }
  };

  return (
    <Form form={form}>
      <Container>
        <Card title={t('biker.selectReportReason')} padding={12}>
          <Field name="reportItem">
            <CheckboxGroup
              onChange={onCheck}
              options={items.map(({ id, taskName, commentable }) => ({
                label: taskName,
                value: id,
                text: commentable ? text : '',
                suffix: commentable ? (
                  <Button width="100px" label={t('biker.edit')} shape="capsule" size="small" color="plan" />
                ) : null,
                onSuffixClick: onCommentSuffixClick,
              }))}
            />
          </Field>
        </Card>
        <Field name="photos">
          <CardPhotoEditor max={3} />
        </Field>
      </Container>
      <Field name="address">
        <PullUpMapLocate
          onChange={onAddressChange}
          key="locate"
          defaultPosition={position}
          visible={isLocaterVisible}
          onClose={onMapClose}
        />
      </Field>
      <PopupInputForm
        visible={isInputTextVisible}
        fieldName={'TaskText'}
        onClose={() => setIsInputTextVisible(false)}
        onConfirm={handleOnConfirm}
        title={''}
        defaultValue={text}
      />
      <ButtonContainer>
        <Button
          label={t('biker.report')}
          shape="round"
          width="100%"
          onClick={submitForm}
          disabled={!form.getFieldValue('reportItem') || form.getFieldValue('reportItem').length === 0 || loading}
        />
      </ButtonContainer>
    </Form>
  );
};

export default ReportForm;
