import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

interface SnackbarConfig {
  id: number;
  message: string;
  type?: "warning" | "normal";
  duration: number;
  suffix?: string;
  onSuffixClick?: () => void;
}

export interface NotificationState {
  queue: SnackbarConfig[];
}

const initialState = {
  queue: [] as SnackbarConfig[]
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    enqueueNotification: (state, action: PayloadAction<Omit<SnackbarConfig, "id">>) => {
      state.queue = [...state.queue, { ...action.payload, id: Date.now() }];
    },
    dequeueNotification: state => {
      state.queue = state.queue.slice(1);
    },
    resetQueue: state => {
      state.queue = initialState.queue;
    }
  }
});

export const { enqueueNotification, dequeueNotification, resetQueue } = notificationSlice.actions;

export const selectNotification = (state: RootState): NotificationState => state.notification;

export default notificationSlice.reducer;
