import { variant } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
import { ButtonStyledProps } from "./index";

export const buttonSize: any = (props: ButtonStyledProps) =>
  variant({
    prop: "buttonSize",
    variants: {
      medium: {
        ...themeGet(`fonts.button`)(props),
        width: 300,
        height: 56
      },
      small: {
        ...themeGet(`fonts.button`)(props),
        width: 205,
        fontSize: 14,
        height: 40
      }
    }
  });

export const common: any = () => ({
  border: "none",
  outline: "none",
  cursor: "pointer",
  fontWeight: 400,
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  userSelect: "none"
});

export const color: any = () =>
  variant({
    prop: "color",
    variants: {
      plan: {
        backgroundColor: "white",
        border: "1px solid",
        borderColor: "secondary"
      },
      primary: {
        backgroundColor: "primary",
        color: "white"
      },
      secondary: {
        backgroundColor: "secondary"
      },
      warning: {
        backgroundColor: "error",
        color: "white"
      }
    }
  });

export const disabled: any = () =>
  variant({
    prop: "disabled",
    variants: {
      true: {
        backgroundColor: "gray100",
        color: "secVariant",
        cursor: "not-allowed"
      }
    }
  });

export const shape: any = (props: ButtonStyledProps) =>
  variant({
    prop: "shape",
    variants: {
      circle: {
        borderRadius: "50%",
        width: props?.buttonSize === "small" ? 40 : 50,
        height: props?.buttonSize === "small" ? 40 : 50,
        flexShrink: 0
      },
      capsule: {
        padding: ["8px 20px", "8px 25px"],
        borderRadius: 50
      },
      round: {
        borderRadius: 2
      },
      sharp: {}
    }
  });
