import React, { Fragment } from "react";
import styled from "styled-components";
import Card from "@atoms/Card";
import Button from "@atoms/Button";
import ScooterInfo from "@organisms/ScooterInfo";
import { useTranslation } from "react-i18next";
import useFadeIn from "@hooks/useFadeIn";
import { themeGet } from "@styled-system/theme-get";

export interface CardOwnedOrderProps {
  scooterId: string;
  className?: string;
  isMapMode: boolean;
  onViewOrder?: () => void;
  onCancelReport?: () => void;
}

const CardStyled = styled(Card)<{
  className?: string;
  hasRadius: boolean;
  shouldFixed: boolean;
}>`
  border-radius: ${({ hasRadius }) => (hasRadius ? 5 : 0)}px;
  position: ${({ shouldFixed }) => (shouldFixed ? "fixed" : "relative")};
  bottom: ${({ shouldFixed }) => (shouldFixed ? 0 : "auto")};
  width: calc(100% - 16px);
  margin: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const ScooterInfoStyled = styled(ScooterInfo)`
  margin: 4px 0;
`;

const Text = styled.div`
  ${themeGet("fonts.subtitle")};
  color: ${themeGet("colors.black")};
`;

const CardOwnedOrder: React.FC<CardOwnedOrderProps> = ({
  scooterId,
  onViewOrder,
  isMapMode,
  className,
}) => {
  const { t } = useTranslation();
  const { transitions } = useFadeIn(isMapMode);

  const handleViewOrder = () => {
    typeof onViewOrder === "function" && onViewOrder();
  };

  return (
    <Fragment>
      {transitions.map(({ props, key }) => (
        <CardStyled
          className={className}
          padding={12}
          hasRadius={isMapMode}
          shouldFixed={isMapMode}
          style={props}
          key={key}
        >
          <ScooterInfoStyled scooterId={scooterId} />
          <Text>{t("biker.reportYourScooter")}</Text>
          <ButtonContainer>
            <Fragment>
              <Button
                shape="round"
                label={t("biker.checkOrder")}
                color="primary"
                size="small"
                width="100%"
                onClick={handleViewOrder}
              />
            </Fragment>
          </ButtonContainer>
        </CardStyled>
      ))}
    </Fragment>
  );
};

export default CardOwnedOrder;
