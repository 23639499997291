import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/App";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/store";
import theme from "./theme";
import "./i18n";
import { BrowserRouter as Router, Route } from "react-router-dom";
import VConsole from "vconsole";
import Loading from "@atoms/Loading";

if (process.env.NODE_ENV === "development") {
  new VConsole();
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Suspense fallback={Loading}>
          <Router>
            <Route path="/" component={App} />
          </Router>
        </Suspense>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
