import React, { ReactNode } from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { PaddingProps, padding } from "styled-system";
import { animated, AnimatedValue, ForwardedProps } from "react-spring";

export interface CardProps {
  title?: string | ReactNode;
  padding?: PaddingProps["padding"];
  className?: string;
  style?: AnimatedValue<ForwardedProps<ForwardedProps<React.CSSProperties>>>;
  onClick?: () => void;
}

const Title = styled.h2<PaddingProps>`
  ${padding}
  padding-top:0;
  padding-bottom: 0;
  ${themeGet(`fonts.subtitle`)};
`;

const Container = styled(animated.div)<PaddingProps>`
  ${padding}
  background: white;
  box-shadow: 0px 1px 0 ${themeGet(`colors.secondary`)};
`;

const Wrapper = styled.div`
  margin-bottom: 12px;
`;

const Card: React.FC<CardProps> = ({
  title,
  onClick,
  padding,
  children,
  style,
  className,
}) => {
  const handleOnClick = () => {
    typeof onClick === "function" && onClick();
  };

  return (
    <Wrapper>
      <Title padding={padding}>{title}</Title>
      <Container
        style={style}
        onClick={handleOnClick}
        padding={padding}
        className={className}
      >
        {children}
      </Container>
    </Wrapper>
  );
};

export default Card;
