// copy from UI
const theme = {
  fonts: {
    button: {
      fontSize: 17,
      letterSpacing: 1
    },
    subtitle: {
      lineHeight: 2,
      fontSize: 16,
      letterSpacing: 1,
      fontWeight: 500,
      color: "#7f7f7f"
    },
    body1: {
      fontSize: 16,
      letterSpacing: 1,
      fontWeight: 400,
      color: "white"
    },
    body2: {
      fontSize: 14,
      letterSpacing: 1,
      fontWeight: 400
    },
    headline2: {
      fontSize: 27,
      letterSpacing: 1,
      fontWeight: 400
    }
  },
  colors: {
    background: "#f6f6f6",
    primaryGreen: "#99ca46",
    tiffanyGreen: "#B3E3D1",
    primary: "#89b53e",
    primaryBlue: "#4ac7e9",
    priVariant: "#6b8d30",
    secondary: "#e5e5e5",
    secVariant: "#b2b2b2",
    error: "#ff452e",
    gray100: "#eeeeee",
    gray300: "#cccccc",
    gray500: "#7f7f7f",
    gray600: "#666666",
    gray700: "#4c4c4c",
    gray800: "#323232",
    gray900: "#191919",
    black: "#292929",
    gpsBlueSup: "#389ee32b",
    yellow50: "#fff3d3",
    yellow100: "#ffe8a8",
    yellow200: "#ffdc7d",
    yellow300: "#ffd152",
    yellow400: "#ffc627",
    yellow500: "#cc9e1f",
    yellow600: "#b28a1b",
    yellow700: "#7f6313",
    green50: "#eaf4d9",
    green100: "#d6e9b4",
    green200: "#c1df8f",
    green300: "#add46a",
    green400: "#99ca45",
    green700: "#4c6522",
    red50: "#ffd9d5",
    blue400: "#2e75ff"
  }
};

export default theme;
