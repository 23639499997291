import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import isNil from 'lodash.isnil';

import Input from '@atoms/Input';
import PullUpSearchForm, { PullUpSearchFormProps } from '@molecules/PullUpSearchForm';
import { ReactComponent as IconFilter } from '@assets/iconFilter.svg';
import { ReactComponent as IconSearch } from '@assets/iconSearch.svg';

export interface InputSearchProps {
  className?: string;
  onFilterChange: (filter: { scooterId: string; includeTaskItems: string[] }) => void;
  defaultFilter: { scooterId: string; includeTaskItems: string[] };
  items: { id: string; taskName: string }[] | null;
  showSelectAll?: PullUpSearchFormProps['showSelectAll'];
}

const Container = styled.div``;

const InputStyled = styled(Input)`
  margin-right: 8px;
  text-transform: uppercase;
  width: 89%;
`;

export const ScooterIdPattern = /(^\d{2,6}|^[A-Z]{2,6})-(\d{3,6}|[A-Z]{3,6})$/;

const excludedCheckedItemName = '消毒';

type TFilterData = {
  scooterId: string;
  includeTaskItems: string[];
};

const InputSearch = forwardRef<{ resetFields: () => void }, InputSearchProps>(
  ({ className, items = null, defaultFilter, onFilterChange, showSelectAll }, ref) => {
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [filterData, setFilterData] = useState<TFilterData>({
      scooterId: '',
      includeTaskItems: [],
    });

    const searchOptions =
      useMemo(
        () =>
          items?.map(({ id, taskName }) => ({
            label: taskName,
            value: id,
          })),
        [items]
      ) || [];

    const onInputChange = (scooterId: string | undefined) => {
      if (isNil(scooterId)) return;
      setFilterData({ scooterId: scooterId.toUpperCase(), includeTaskItems: [] });
    };

    const onItemChange = (filter: { includeTaskItems: string[] }) => {
      const { includeTaskItems } = filter;
      setFilterData({ scooterId: '', includeTaskItems });
    };

    const debouncedOnFilterChange = useDebouncedCallback((filterData: TFilterData) => {
      onFilterChange(filterData);
    }, 500);

    useImperativeHandle(
      ref,
      () => ({
        gerFields: () => {
          return filterData;
        },
        resetFields: () =>
          setFilterData({
            scooterId: '',
            includeTaskItems: [],
          }),
      }),
      []
    );

    useEffect(() => {
      // 進入app後，預設篩選器：全選但不勾選消毒車輛
      if (defaultFilter.includeTaskItems.length === 0) {
        setFilterData({
          ...filterData,
          includeTaskItems: items
            ? [...items.filter(({ taskName }) => taskName !== excludedCheckedItemName).map(({ id }) => id)]
            : [],
        });
      } else {
        setFilterData({
          ...filterData,
          includeTaskItems: [...defaultFilter.includeTaskItems],
        });
      }
    }, [items]);

    useEffect(() => {
      debouncedOnFilterChange(filterData);
    }, [filterData]);

    useEffect(
      () => () => {
        debouncedOnFilterChange.cancel();
      },
      []
    );

    return (
      <Container className={className}>
        <InputStyled
          Icon={IconSearch}
          clearable={true}
          placeholder="Scooter ID"
          onChange={onInputChange}
          value={filterData.scooterId}
        />
        <IconFilter
          onClick={() => {
            setIsFilterVisible(true);
          }}
        />
        <PullUpSearchForm
          searchOptions={searchOptions}
          value={filterData.includeTaskItems}
          visible={isFilterVisible}
          onClose={() => setIsFilterVisible(false)}
          onConfirm={onItemChange}
          showSelectAll={showSelectAll}
        />
      </Container>
    );
  }
);

export default InputSearch;
