import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectAssignedOrders,
  selectSelectedOrders,
  setCurrentOrder,
  selectOwnOrderLoading,
  removeOrder,
  getOrders,
  resetCurrentOrder,
} from '@redux/ownedOrders';
import { themeGet } from '@styled-system/theme-get';
import { AppDispatch } from '@redux/store';
import CardOrderItem from '@organisms/CardOrderItem';
import Count from '@atoms/Count';
import { RouterProps } from 'react-router';
import { enqueueNotification } from '@redux/notification';
import Loading from '@atoms/Loading';
import { useTranslation } from 'react-i18next';
import { Order } from '~/definition/order';

export interface DispatchListProps extends RouterProps {
  headerPortal?: HTMLDivElement;
}

const Container = styled.div`
  padding: 60px 0;
`;

const CardContainer = styled.div`
  padding: 8px 0;
`;

const CountContainer = styled.div`
  background-color: white;
  float: left;
`;

const Title = styled.div`
  padding: 0 8px;
  ${themeGet(`fonts.subtitle`)};
`;

const DispatchList: React.FC<DispatchListProps> = ({ history, headerPortal }) => {
  const { assignedOrders, assignedCount } = useSelector(selectAssignedOrders);
  const { selectedOrders, selectedCount } = useSelector(selectSelectedOrders);
  const loading = useSelector(selectOwnOrderLoading);
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const removeThisOrder = async (id: string) => {
    const action = await dispatch(removeOrder(id));
    if (removeOrder.fulfilled.match(action)) {
      dispatch(
        enqueueNotification({
          message: t('biker.removeSucceeded'),
          duration: 2000,
        })
      );
    } else if (removeOrder.rejected.match(action)) {
      dispatch(
        enqueueNotification({
          message: t('biker.removeFailed'),
          duration: 2000,
          type: 'warning',
        })
      );
    }
  };

  const toDetailPage = (order: Order) => {
    dispatch(setCurrentOrder(order));
    history.push(`/dispatch/${order.id}/form`);
  };

  useEffect(() => {
    dispatch(getOrders());
    dispatch(resetCurrentOrder());
  }, []);

  return (
    <Container>
      {headerPortal &&
        ReactDOM.createPortal(
          <CountContainer>
            <Count count={assignedCount} type="assign" />
            <Count count={selectedCount} type="pool" />
          </CountContainer>,
          headerPortal
        )}
      <CardContainer>
        {assignedCount !== null && assignedCount > 0 && <Title>{t('biker.assignedOrder')}</Title>}
        {assignedOrders.map((order) => (
          <CardOrderItem onClick={toDetailPage} key={order.id} orderInfo={order} isAddMode={false} />
        ))}
        {selectedCount !== null && selectedCount > 0 && <Title>{t('biker.poolOrder')}</Title>}
        {selectedOrders.map((order) => (
          <CardOrderItem
            onClick={toDetailPage}
            key={order.id}
            orderInfo={order}
            isAddMode={false}
            isRemoveMode={true}
            onRemoveClick={removeThisOrder}
          />
        ))}
      </CardContainer>
      <Loading visible={loading} />
    </Container>
  );
};

export default DispatchList;
