import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from './store';
import {
  getOrders as getOrdersApi,
  reportAbnormal,
  completeOrder as completeOrderApi,
  getOrderById,
  removeOrdersByIds,
} from '@apis/dispatch';
import { dispatchStates } from '@configs/.';
import { isPendingAction, isSucceededAction, isFailedAction } from './utils';
import { Order } from '~/definition/order';
import { PinData } from '@molecules/MapInfo';

export interface OwnedOrdersState {
  loading: boolean;
  selectedOrders: Order[];
  selectedCount: number | null;
  assignedOrders: Order[];
  assignedCount: number | null;
  unAcceptedOrders: Order[];
  unAcceptedCount: number | null;
  currentOrder: Order | PinData | null;
}

const initialState: OwnedOrdersState = {
  loading: false,
  selectedOrders: [],
  selectedCount: null,
  assignedOrders: [],
  assignedCount: null,
  unAcceptedOrders: [],
  unAcceptedCount: null,
  currentOrder: null,
};

export const getOrders = createAsyncThunk('ownedOrders/getOrders', async (data, thunkApi) => {
  try {
    const response = await getOrdersApi();
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue('fail');
  }
});

export const getCurrentOrder = createAsyncThunk('ownedOrders/getCurrentOrder', async (id: string, thunkApi) => {
  try {
    const response = await getOrderById(id);
    return response;
  } catch (err) {
    return thunkApi.rejectWithValue('fail');
  }
});

export const removeOrder = createAsyncThunk('ownedOrders/removeOrder', async (id: string, thunkApi) => {
  try {
    await removeOrdersByIds([id]);
    return id;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const completeOrder = createAsyncThunk(
  'ownedOrders/completeOrder',
  async (
    formData: {
      orderId: string;
      completedTaskIds: string[];
      address: string;
      comment: string;
      photos: string[];
    },
    thunkApi
  ) => {
    try {
      const data = await completeOrderApi(formData);
      return data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const reportOrderIssue = createAsyncThunk(
  'ownedOrders/reportOrderIssue',
  async (formData: { orderId?: string; photos: []; address: string; reportItems: string[] }, thunkApi) => {
    try {
      const data = await reportAbnormal(formData);
      return data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const ownedOrdersSlice = createSlice({
  name: 'ownedOrders',
  initialState,
  reducers: {
    setCurrentOrder: (state, action: PayloadAction<Order>) => {
      state.currentOrder = action.payload;
    },
    setOwnedOrders: (state, action: PayloadAction<OwnedOrdersState>) => {
      state = action.payload;
    },
    setSelectedOrders: (state, action: PayloadAction<{ orders: Order[] }>) => {
      state.selectedOrders = action.payload.orders;
      state.selectedCount = action.payload.orders.length;
    },
    setAssignedOrders: (state, action: PayloadAction<{ orders: Order[] }>) => {
      state.assignedOrders = action.payload.orders;
      state.assignedCount = action.payload.orders.length;
    },
    resetSelectedOrders: (state) => {
      state.selectedOrders = initialState.selectedOrders;
      state.selectedCount = initialState.selectedCount;
    },
    resetAssignedOrders: (state) => {
      state.assignedOrders = initialState.assignedOrders;
      state.assignedCount = initialState.assignedCount;
    },
    setUnAcceptedOrders: (state, action: PayloadAction<{ orders: Order[] }>) => {
      state.unAcceptedOrders = action.payload.orders;
      state.unAcceptedCount = action.payload.orders.length;
    },
    resetUnAcceptedOrders: (state) => {
      state.unAcceptedOrders = initialState.unAcceptedOrders;
      state.unAcceptedCount = initialState.unAcceptedCount;
    },
    resetCurrentOrder: (state) => {
      state.currentOrder = null;
    },
    resetOwnedOrders: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      if (payload.assigned?.length > 0) {
        const result = payload.assigned.reduce(
          (result: { unAccepted: Order[]; owned: Order[] }, order: Order) => {
            if (order.state === dispatchStates.assignedOrder) {
              result = { ...result, unAccepted: [...result.unAccepted, { ...order }] };
            } else {
              result = { ...result, owned: [...result.owned, { ...order }] };
            }
            return result;
          },
          { unAccepted: [], owned: [] }
        );

        state.unAcceptedOrders = result.unAccepted;
        state.unAcceptedCount = result.unAccepted.length;

        state.assignedOrders = result.owned;
        state.assignedCount = result.owned.length;
      } else {
        state.assignedCount = 0;
        state.unAcceptedCount = 0;
        state.unAcceptedOrders = [];
        state.assignedOrders = [];
      }

      if (payload.selected?.length > 0) {
        state.selectedOrders = payload.selected;
        state.selectedCount = payload.selected.length;
      } else {
        state.selectedCount = 0;
        state.selectedOrders = [];
      }
    });
    builder.addCase(removeOrder.fulfilled, (state, { payload }) => {
      state.selectedOrders = state.selectedOrders.filter(({ id }) => id !== payload);
      state.selectedCount = state.selectedOrders.length;
      state.currentOrder = null;
    });
    builder.addCase(getCurrentOrder.fulfilled, (state, { payload }) => {
      state.currentOrder = payload;
    });
    builder.addMatcher(isPendingAction('ownedOrders'), (state) => {
      state.loading = true;
    });
    builder.addMatcher(isSucceededAction('ownedOrders'), (state) => {
      state.loading = false;
    });
    builder.addMatcher(isFailedAction('ownedOrders'), (state) => {
      state.loading = false;
    });
  },
});

export const {
  setSelectedOrders,
  setAssignedOrders,
  setCurrentOrder,
  resetCurrentOrder,
  resetSelectedOrders,
  resetAssignedOrders,
  resetOwnedOrders,
  setUnAcceptedOrders,
  resetUnAcceptedOrders,
} = ownedOrdersSlice.actions;

export const selectOwnedOrders = (state: RootState): OwnedOrdersState => state.ownedOrders;

export const selectUnAcceptedOrders = (
  state: RootState
): {
  unAcceptedOrders: Order[];
  unAcceptedCount: number | null;
} => ({
  unAcceptedOrders: state.ownedOrders.unAcceptedOrders,
  unAcceptedCount: state.ownedOrders.unAcceptedCount,
});

export const selectUnAcceptedCount = (state: RootState): number | null => state.ownedOrders.unAcceptedCount;

export const selectSelectedOrders = (
  state: RootState
): {
  selectedOrders: Order[];
  selectedCount: number | null;
} => ({
  selectedOrders: state.ownedOrders.selectedOrders,
  selectedCount: state.ownedOrders.selectedCount,
});

export const selectAssignedOrders = (
  state: RootState
): {
  assignedOrders: Order[];
  assignedCount: number | null;
} => ({
  assignedOrders: state.ownedOrders.assignedOrders,
  assignedCount: state.ownedOrders.assignedCount,
});

export const selectOwnOrderLoading = (state: RootState): boolean => state.ownedOrders.loading;

export const selectCurrentOrder = (state: RootState): Order => state.ownedOrders.currentOrder as Order;

export default ownedOrdersSlice.reducer;
