import moment from "moment";

export const generateFormData = (data: Record<string, any>) => {
    const formData = new FormData();
    Object.entries(data).forEach(([key, val]) => {
        if (moment.isMoment(val)) {
            formData.append(key, val.toISOString());
        } else if (Array.isArray(val)) {
            val.forEach(v => {
                formData.append(`${key}`, v);
            });
        } else if (val !== null && typeof val === "object" && !(val instanceof File)) {
            Object.entries(val).forEach(([nestKey, nestVal]) => {
                formData.append(`${key}[${nestKey}]`, nestVal as string);
            });
        } else {
            formData.append(key, val);
        }
    });

    return formData;
};