import React from "react";
import CardOwnedOrder from "@organisms/CardOwnedOrder";
import CardReserve from "@organisms/CardReserve";
import { searchScooterTypes } from '@configs/.'

// 傳送預約
export const getScooterTypeComponent = ({
    onReserveClick,
    isMapMode,
    scooterId,
    onViewOrder,
    type
}: {
    isMapMode: boolean;
    type: searchScooterTypes;
    scooterId: string;
    onViewOrder?: () => void;
    onReserveClick?: () => void;
}) => {
    return (): JSX.Element | null => {
        if (type === searchScooterTypes.ownedOrder) {
            return <CardOwnedOrder isMapMode={isMapMode} scooterId={scooterId} onViewOrder={onViewOrder} />;
        } else if (type === searchScooterTypes.othersOrder) {
            return <CardReserve status="others" isMapMode={isMapMode} scooterId={scooterId} />;
        } else if (type === searchScooterTypes.reservable) {
            return <CardReserve onReserveClick={onReserveClick} status="reservable" isMapMode={isMapMode} scooterId={scooterId} />;
        } else if (type === searchScooterTypes.doable) {
            return <CardReserve onReserveClick={onReserveClick} status="doable" isMapMode={isMapMode} scooterId={scooterId} />;
        } else if (type === searchScooterTypes.unavailable) {
            return <CardReserve onReserveClick={onReserveClick} status="unavailable" isMapMode={isMapMode} scooterId={scooterId} />;
        } else {
            return null;
        }
    };
};
