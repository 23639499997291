import React, { useState, useRef, ChangeEvent, ComponentType } from "react";
import styled from "styled-components";
import { layout, LayoutProps } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
import { ReactComponent as IconClose } from "@assets/iconClose.svg";

export interface InputProps {
  value?: string;
  placeholder?: string;
  onChange: (value?: string) => void;
  Icon?: ComponentType | string;
  clearable?: boolean;
  width?: number | string;
  name?: string;
  className?: string;
  size?: "small" | "large";
}

interface InputContainerProps extends LayoutProps {
  hasBorder: boolean;
}

const InputContainer = styled.label<InputContainerProps>`
  border: 1px solid;
  border-color: ${({ hasBorder }) => (hasBorder ? "black" : "transparent")};
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  padding: 0 4px;
  background-color: ${themeGet("colors.gray100")};
  ${layout};
`;

const InputStyled = styled.input<LayoutProps>`
  border: none;
  outline: none;
  background: none !important;
  font-size: 16px;
  width: 91%;
  ${layout};
`;

const PrefixIcon = styled.img<{ as?: ComponentType }>`
  vertical-align: top;
  margin: 3px 0;
  vertical-align: middle;
`;

const ClearIcon = styled(IconClose)
  .withConfig({
    shouldForwardProp: (props, defaultValidatorFn) => !['isShow'].includes(props),
  }) <{ isShow: boolean }>`
  visibility: ${({ isShow }) => (isShow ? "visible" : "hidden")};
  vertical-align: top;
  margin: 3px 0;
  vertical-align: middle;
`;

const Input: React.FC<InputProps> = ({
  value,
  name,
  placeholder,
  onChange,
  size = "small",
  Icon,
  clearable = false,
  width,
  className
}) => {
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocus, setIsFocus] = useState(false);

  const handleOnFocus = () => {
    setIsFocus(true);
  };

  const handleOnBlur = () => {
    setIsFocus(false);
  };

  const handleOnChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = evt?.target?.value;
    updateValue(value);
  };

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current?.focus();
    }
  };

  const updateValue = (value: string) => {
    typeof onChange === "function" ? onChange(value) : setInputValue(value);
  };

  const handleClear = () => {
    updateValue("");
  };

  return (
    <InputContainer
      onClick={focusInput}
      hasBorder={isFocus}
      width={width}
      className={className}
      height={size === "small" ? 30 : 48}
    >
      {Icon &&
        (typeof Icon === "string" ? (
          <PrefixIcon src={Icon} alt={`${placeholder} input icon`} />
        ) : (
          <PrefixIcon as={Icon} />
        ))}
      <InputStyled
        ref={inputRef}
        name={name}
        value={!value && value !== "" ? inputValue : value}
        placeholder={placeholder}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        height={size === "small" ? 30 : 48}
      />
      <ClearIcon onClick={handleClear} isShow={(inputValue || value) && clearable ? true : false} />
    </InputContainer>
  );
};

export default Input;
