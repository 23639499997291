import React, { useEffect } from 'react';
import { getMissingScooterItems, selectMissingScooterItems } from '@redux/reportableItems';
import { AppDispatch } from '@redux/store';
import { useDispatch, useSelector } from 'react-redux';
import ReportForm from '@organisms/ReportForm';
import { selectPosition } from '@redux/user';
import { RouteComponentProps } from 'react-router';
import { reportOrderIssue, selectOwnedOrders } from '@redux/ownedOrders';
import { enqueueNotification } from '@redux/notification';
import { useTranslation } from 'react-i18next';

const MissingScooterReport: React.FC<RouteComponentProps<{ [x: string]: string }>> = ({ match, history }) => {
  const missingScooterItems = useSelector(selectMissingScooterItems);
  const position = useSelector(selectPosition);
  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector(selectOwnedOrders);
  const { t } = useTranslation();

  const onReport = async (data: { id?: string; photos: []; address: string; reportItems: string[] }) => {
    const action = await dispatch(reportOrderIssue({ ...data, orderId: data.id }));
    if (reportOrderIssue.fulfilled.match(action)) {
      dispatch(
        enqueueNotification({
          message: t('biker.reportSucceeded'),
          duration: 2000,
        })
      );
      history.replace(`/dispatch-list`);
    }
  };

  useEffect(() => {
    if (!missingScooterItems) {
      dispatch(getMissingScooterItems());
    }
  }, []);

  return (
    <div>
      <ReportForm
        loading={loading}
        onReport={onReport}
        items={missingScooterItems || []}
        position={position}
        id={match.params.id}
      />
    </div>
  );
};

export default MissingScooterReport;
