import React, { FC, useEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { zIndex, ZIndexProps } from "styled-system";

import Portal, { PortalProps } from "./Portal";
import { themeGet } from "@styled-system/theme-get";

const Square = styled.div`
  box-sizing: border-box;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
`;

const Dot = styled.div`
  display: inline-block;
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-left: 10px;

  animation-name: ${fadeIn};
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;

  &:first-child {
    margin-left: 0;
  }
`;

const LeftDot = styled(Dot)`
  background: ${themeGet("colors.primaryBlue")};
`;

const MiddleDot = styled(Dot)`
  background: ${themeGet("colors.tiffanyGreen")};
  animation-delay: 0.1s;
`;

const RightDot = styled(Dot)`
  background: ${themeGet("colors.primaryGreen")};
  animation-delay: 0.2s;
`;

const Mask = styled.div<ZIndexProps>`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${zIndex};
`;

export interface LoadingProps extends PortalProps, ZIndexProps {
  visible: boolean;
}

export const PureLoading: FC<LoadingProps> = props => {
  const { getContainer, visible, zIndex } = props;
  const mask = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const portal = mask.current;
    if (portal) disableBodyScroll(portal);
    return () => {
      if (portal) enableBodyScroll(portal);
    };
  }, [visible]);

  return (
    <React.Fragment>
      {visible && (
        <Portal getContainer={getContainer}>
          <Mask ref={mask} zIndex={zIndex}>
            <Square>
              <LeftDot />
              <MiddleDot />
              <RightDot />
            </Square>
          </Mask>
        </Portal>
      )}
    </React.Fragment>
  );
};

const defaultProps: Partial<LoadingProps> = {
  zIndex: 9
};
PureLoading.defaultProps = defaultProps;

export default React.memo(PureLoading);
