import React from "react";
import styled from "styled-components";
import Popup from "@atoms/Popup";

export interface PopupPhotoProps {
  visible: boolean;
  base64: string | null;
  onClose: () => void;
}

const Image = styled.img`
  width: 100%;
`;

const PopupPhoto: React.FC<PopupPhotoProps> = ({
  visible,
  base64,
  onClose,
}) => {
  return (
    <Popup onlyShowsConfirm={true} visible={visible} onClose={onClose}>
      {base64 && <Image src={base64} alt="image" />}
    </Popup>
  );
};

export default PopupPhoto;
