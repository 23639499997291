import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { themeGet } from "@styled-system/theme-get";
import { ReactComponent as IconSearch } from "@assets/iconSearch.svg";
import { ReactComponent as IconPerson } from "@assets/iconPerson.svg";
import { ReactComponent as IconTask } from "@assets/iconTask.svg";

export interface NavProps {
  currentPath: string;
}

const Container = styled.nav`
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  background-color: white;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  height: 60px;
  align-items: center;
  border-top: 2px solid ${themeGet("colors.gray300")};
`;

const LinkStyled = styled(Link)`
  width: 33%;
  text-align: center;
`;

const navConfig = [
  {
    Icon: IconSearch,
    path: "/biker-pool"
  },
  {
    Icon: IconTask,
    path: "/dispatch-list",
    alias: "/"
  },
  {
    Icon: IconPerson,
    path: "/account"
  }
];

const Nav: React.FC<NavProps> = ({ currentPath }) => {
  return navConfig.find(({ path }) => path === currentPath) || currentPath === "/" ? (
    <Container>
      {navConfig.map(({ Icon, path, alias }) => (
        <LinkStyled to={path} key={path}>
          <Icon
            stroke={currentPath === path || currentPath === alias ? "black" : "gray"}
            fill={currentPath === path ? "black" : "gray"}
          />
        </LinkStyled>
      ))}
    </Container>
  ) : null;
};

export default Nav;
