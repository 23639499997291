import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { themeGet } from "@styled-system/theme-get";
import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  getCurrentOrder,
  selectCurrentOrder,
  selectPoolOrderLoading,
} from "@redux/poolOrders";
import { AppDispatch } from "@redux/store";
import { orderType } from "@configs/.";
import ScooterInfo from "@organisms/ScooterInfo";
import ItemTextOrigin from "@molecules/ItemTextOrigin";
import Button from "@atoms/Button";
import Card from "@atoms/Card";
import Loading from "@atoms/Loading";
import ItemText from "@atoms/ItemText";
import { ReactComponent as IconDot } from "@assets/iconDot.svg";
import usePoolOrder from "@hooks/usePoolOrders";

const Container = styled.div`
  padding-bottom: 80px;
  position: relative;
`;

const Header = styled.div`
  background-color: white;
  padding: 12px;
`;

const Text = styled.span`
  ${themeGet("fonts.body1")};
  color: ${themeGet("colors.black")};
`;

const IconTitle = styled.span`
  vertical-align: bottom;
  line-height: 40px;
  display: inline-block;
  margin-right: 8px;
`;

const ScooterInfoStyled = styled(ScooterInfo)`
  margin-bottom: 8px;
`;

const ButtonAdd = styled(Button)<{ onClick: () => void }>`
  transform: scale(1);
  right: 16px;
  top: 4px;
  position: absolute;
`;

const DispatchView: React.FC<RouteComponentProps<{ [x: string]: string }>> = ({
  match,
}) => {
  const currentOrder = useSelector(selectCurrentOrder);
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(selectPoolOrderLoading);
  const { t } = useTranslation();
  const { acceptThisOrder } = usePoolOrder();

  const onAcceptOrder = () => {
    acceptThisOrder(match.params.id, "/dispatch-list");
  };

  useEffect(() => {
    if (!currentOrder) {
      dispatch(getCurrentOrder(match.params.id));
    }
  }, []);

  return currentOrder ? (
    <Fragment>
      <Container>
        <Header>
          <ScooterInfoStyled scooterId={currentOrder.scooterId} />
          {currentOrder.orderType === orderType.BIKER_POOL && (
            <ButtonAdd
              shape="capsule"
              size="small"
              label={t("biker.add")}
              color="plan"
              width="30%"
              onClick={onAcceptOrder}
            />
          )}
          {currentOrder.origin && <ItemTextOrigin {...currentOrder.origin} />}
        </Header>
        {currentOrder.managerComment && (
          <Card title={t("biker.managerComment")} padding={12}>
            <Text>{currentOrder.managerComment}</Text>
          </Card>
        )}
        <Card
          title={
            <span>
              <IconTitle>{t("biker.task")} </IconTitle>
            </span>
          }
          padding={12}
        >
          {currentOrder.tasks?.map(
            ({ name, taskName, taskId, maintenanceItem, description }) => (
              <ItemText
                key={taskId}
                Icon={IconDot}
                title={`${name || taskName}`}
                subtitle={
                  maintenanceItem?.id ? `Item ID ${maintenanceItem?.id}` : ""
                }
                shouldExpand={true}
                text={description || maintenanceItem?.description}
              />
            )
          )}
        </Card>
      </Container>
      <Loading visible={loading} />
    </Fragment>
  ) : (
    <Loading visible={true} />
  );
};

export default DispatchView;
