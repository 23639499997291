import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dequeueNotification, selectNotification } from "@redux/notification";
import Snackbar from "@atoms/Snackbar";
interface SnackbarConfig {
  id: number;
  message: string;
  type?: "warning" | "normal";
  duration: number;
  suffix?: string;
  onSuffixClick?: () => void;
}

const Notification: React.FC = () => {
  const { queue } = useSelector(selectNotification);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [currentConfig, setCurrentConfig] = useState<SnackbarConfig | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentConfig !== null) {
      setIsSnackbarVisible(true);
      setTimeout(() => {
        dispatch(dequeueNotification());
      }, currentConfig.duration + 300);
    }
  }, [currentConfig]);

  useEffect(() => {
    if (!queue[0]) {
      setCurrentConfig(null);
    } else if (queue[0].id !== currentConfig?.id) {
      setCurrentConfig(queue[0]);
    }
  }, [queue, currentConfig]);

  return (
    <Fragment>
      {currentConfig && <Snackbar onClose={() => setIsSnackbarVisible(false)} visible={isSnackbarVisible} {...currentConfig} />}
    </Fragment>
  );
};

export default Notification;
