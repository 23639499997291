import React, { useEffect } from 'react';
import PullUp from '@atoms/PullUp';
import { CheckboxGroup, CheckboxGroupProps } from '@atoms/Checkbox';
import Form, { Field, useForm } from 'rc-field-form';
import { useTranslation } from 'react-i18next';
import { Option } from '~/definition/general';

export interface PullUpSearchFormProps {
  visible: boolean;
  onConfirm: (value: { includeTaskItems: string[] }) => void;
  onClose: () => void;
  value: string[];
  searchOptions: Option[];
  showSelectAll?: CheckboxGroupProps['showSelectAll'];
}

const PullUpSearchForm: React.FC<PullUpSearchFormProps> = ({
  visible,
  searchOptions = [],
  value,
  onConfirm,
  onClose,
  showSelectAll,
}) => {
  const [form] = useForm();
  const { t } = useTranslation();

  const handleOnConfirm = () => {
    onConfirm(form.getFieldsValue());
    onClose();
  };

  const handleOnClose = () => {
    onClose();
  };

  useEffect(() => {
    form.setFieldsValue({ includeTaskItems: value });
  }, [value]);

  return (
    <PullUp title={t('biker.filterOption')} visible={visible} onClose={handleOnClose} onConfirm={handleOnConfirm}>
      <Form form={form}>
        <Field name="includeTaskItems">
          <CheckboxGroup options={searchOptions} showSelectAll={showSelectAll} />
        </Field>
      </Form>
    </PullUp>
  );
};

export default PullUpSearchForm;
