import React, { Fragment } from "react";
import styled from "styled-components";
import Card from "@atoms/Card";
import Button from "@atoms/Button";
import ScooterInfo from "@organisms/ScooterInfo";
import { useTranslation } from "react-i18next";
import useFadeIn from "@hooks/useFadeIn";

enum Status {
  "doable",
  "reservable",
  "unavailable",
  "others",
}

export interface CardReserveProps {
  scooterId: string;
  className?: string;
  isMapMode: boolean;
  status: keyof typeof Status;
  onReserveClick?: () => void;
  onCancelReport?: () => void;
}

const CardStyled = styled(Card)<{
  className?: string;
  hasRadius: boolean;
  shouldFixed: boolean;
}>`
  border-radius: ${({ hasRadius }) => (hasRadius ? 5 : 0)}px;
  position: ${({ shouldFixed }) => (shouldFixed ? "fixed" : "relative")};
  bottom: ${({ shouldFixed }) => (shouldFixed ? 0 : "auto")};
  width: calc(100% - 16px);
  margin: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const ScooterInfoStyled = styled(ScooterInfo)`
  margin: 4px 0;
`;

const Text = styled.div`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.black};
`;

const CardReserve: React.FC<CardReserveProps> = ({
  scooterId,
  status,
  onReserveClick,
  isMapMode,
  className,
}) => {
  const { t } = useTranslation();
  const { transitions } = useFadeIn(isMapMode);

  const handleReserveClick = () => {
    typeof onReserveClick === "function" && onReserveClick();
  };

  return (
    <Fragment>
      {transitions.map(({ props, key }) => (
        <CardStyled
          className={className}
          padding={12}
          hasRadius={isMapMode}
          shouldFixed={isMapMode}
          key={key}
          style={props}
        >
          {status !== "unavailable" ? (
            <ScooterInfoStyled scooterId={scooterId} />
          ) : (
            scooterId
          )}
          {status === "doable" && <Text>{t("biker.doableScooter")}</Text>}
          {status === "reservable" && (
            <Text>{t("biker.reservableScooter")}</Text>
          )}
          {status === "unavailable" && (
            <Text>{t("biker.unavailableScooter")}</Text>
          )}
          {status === "others" && <Text>{t("biker.othersScooter")}</Text>}
          {status !== "unavailable" && status !== "others" && (
            <ButtonContainer>
              <Fragment>
                <Button
                  shape="round"
                  label={t("biker.reserveToReport")}
                  color="primary"
                  size="small"
                  width="100%"
                  onClick={handleReserveClick}
                />
              </Fragment>
            </ButtonContainer>
          )}
        </CardStyled>
      ))}
    </Fragment>
  );
};

export default CardReserve;
