import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import MapInfo, { PinData } from '@molecules/MapInfo';
import CardOrderMap from '@organisms/CardOrderMap';
import Loading from '@atoms/Loading';
import { RouteComponentProps } from 'react-router';
import { resetCurrentOrder, selectCurrentOrder, selectOwnedOrders, setCurrentOrder } from '@redux/ownedOrders';
import { selectPosition } from '@redux/user';
import { controlScooter } from '@apis/scooter';
import { scooterControlActions } from '@configs/.';
import { enqueueNotification } from '@redux/notification';
import { useTranslation } from 'react-i18next';
import Button from '@atoms/Button';
import IconSearch from '@assets/iconSearch.svg';

const CardOrderMapStyled = styled(CardOrderMap)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 16px);
  margin: 8px;
`;

const ButtonToggle = styled(Button)`
  position: absolute;
  right: 8px;
  top: 52px;
  z-index: 2;
`;

export interface DispatchMapProps {
  history: RouteComponentProps['history'];
}

const DispatchMap: React.FC<DispatchMapProps> = ({ history }) => {
  const { selectedOrders, assignedOrders } = useSelector(selectOwnedOrders);
  const currentOrder = useSelector(selectCurrentOrder);
  const position = useSelector(selectPosition);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onPinClick = (order: PinData) => {
    dispatch(setCurrentOrder(order));
  };

  const reportScooter = () => {
    history.push(`/dispatch/${currentOrder.id}/abnormal-report`);
  };

  const viewOrder = () => {
    history.push(`/dispatch/${currentOrder.id}/form`);
  };

  const reportMissingScooter = () => {
    history.push(`/dispatch/${currentOrder.id}/missing-scooter-report`);
  };

  const onMapClick = () => {
    dispatch(resetCurrentOrder());
  };

  const flash = async () => {
    setIsLoading(true);
    try {
      await controlScooter(currentOrder.scooterId, scooterControlActions.FLASH);
      setIsLoading(false);
      dispatch(enqueueNotification({ message: t('biker.controlSucceeded'), duration: 2000 }));
    } catch (error) {
      setIsLoading(false);
      dispatch(enqueueNotification({ message: t('biker.controlFailed'), duration: 2000, type: 'warning' }));
    }
  };

  useEffect(() => {
    if (selectedOrders.length + assignedOrders.length === 0) history.push('/dispatch-list');
  }, []);

  return (
    <div>
      <ButtonToggle
        shape="circle"
        Icon={IconSearch}
        size="small"
        color="plan"
        onClick={() => history.replace('/pool-map')}
      />
      <MapInfo
        onPinClick={onPinClick}
        assignList={assignedOrders}
        selectedPoolList={selectedOrders}
        currentOrder={currentOrder as PinData}
        currentUserPosition={position}
        currentCenterPosition={position}
        onMapClick={onMapClick}
      />
      {currentOrder && (
        <CardOrderMapStyled
          orderInfo={currentOrder}
          isAddMode={false}
          onFlashClick={flash}
          onReportClick={reportScooter}
          onViewOrder={viewOrder}
          onMissingReportClick={reportMissingScooter}
        />
      )}
      <Loading visible={isLoading} />
    </div>
  );
};

export default DispatchMap;
