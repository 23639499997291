import React, { useEffect } from "react";
import styled from "styled-components";
import Popup from "@atoms/Popup";
import Form, { Field, useForm } from "rc-field-form";

export interface PopupInputFormProps {
  visible: boolean;
  title: string;
  defaultValue?: string;
  fieldName?: string;
  onConfirm: (value: string) => void;
  onClose: () => void;
}

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  outline: none;
  padding: 8px;
  font-size: 16px;
`;

const PopupInputForm: React.FC<PopupInputFormProps> = ({
  visible,
  title,
  onConfirm,
  onClose,
  defaultValue = "",
  fieldName = "inputField",
}) => {
  const [form] = useForm();

  const handleOnConfirm = () => {
    onConfirm(form.getFieldValue(fieldName));
    onClose();
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({ [fieldName]: defaultValue });
    }
  }, [visible]);

  return (
    <Popup
      title={title}
      visible={visible}
      onClose={onClose}
      onConfirm={handleOnConfirm}
    >
      <Form form={form} initialValues={{ [fieldName]: defaultValue }}>
        <Field name={fieldName}>
          <TextArea rows={10} />
        </Field>
      </Form>
    </Popup>
  );
};

export default PopupInputForm;
