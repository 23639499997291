import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { themeGet } from '@styled-system/theme-get';
import { useDispatch, useSelector } from 'react-redux';
import { getAbnormalItems, selectAbnormalItems } from '@redux/reportableItems';
import { reportOrderIssue, selectOwnedOrders } from '@redux/ownedOrders';
import { enqueueNotification } from '@redux/notification';
import { reportScooterIssue } from '@redux/reportScooter';
import { selectPosition } from '@redux/user';
import { AppDispatch } from '@redux/store';
import ReportForm from '@organisms/ReportForm';
import PopupDoableOrder from '@molecules/PopupDoableOrder';
import usePoolOrder from '@hooks/usePoolOrders';
import styled from 'styled-components';
import Popup from '@atoms/Popup';

const Text = styled.span`
  ${themeGet('fonts.body1')};
  color: ${themeGet('colors.black')};
`;

const AbnormalReport: React.FC<RouteComponentProps<{ [x: string]: string }>> = ({ match, history }) => {
  const abnormalItems = useSelector(selectAbnormalItems);
  const position = useSelector(selectPosition);
  const dispatch = useDispatch<AppDispatch>();
  const { loading } = useSelector(selectOwnedOrders);
  const { t } = useTranslation();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [doableOrderId, setDoableOrderId] = useState('');
  const reportRedirectPath = match.params.type === 'dispatch' ? '/dispatch-list' : '/biker-pool';
  const { acceptThisOrder } = usePoolOrder();

  const onReport = async (data: { id?: string; photos: []; address: string; reportItems: string[] }) => {
    const { id, ...restData } = data;
    const reportMethod =
      match.params.type === 'dispatch'
        ? reportOrderIssue({ ...restData, orderId: id })
        : reportScooterIssue({ ...restData, scooterId: id });
    const action = await dispatch(reportMethod);

    if (reportOrderIssue.fulfilled.match(action) || reportScooterIssue.fulfilled.match(action)) {
      dispatch(
        enqueueNotification({
          message: t('biker.reportSucceeded'),
          duration: 2000,
        })
      );
      if (action.payload.bikerDoable) {
        setDoableOrderId(action.payload.orderId);
        setIsPopupVisible(true);
      } else {
        history.replace(reportRedirectPath);
      }
    }
    if (reportOrderIssue.rejected.match(action) || reportScooterIssue.rejected.match(action)) {
      dispatch(
        enqueueNotification({
          message: t('biker.reportFailed'),
          duration: 2000,
          type: 'warning',
        })
      );
    }
  };

  const handleDoableConfirm = () => {
    acceptThisOrder(doableOrderId, reportRedirectPath);
    setIsPopupVisible(false);
    history.replace('/dispatch-list');
  };

  const handleDoableReject = () => {
    setIsPopupVisible(false);
    history.replace(reportRedirectPath);
  };

  useEffect(() => {
    if (!abnormalItems) {
      dispatch(getAbnormalItems());
    }
  }, []);

  return (
    <div>
      <ReportForm
        loading={loading}
        items={abnormalItems || []}
        position={position}
        id={match.params.id}
        onReport={onReport}
      />
      {match.params.type === 'dispatch' ? (
        <Popup visible={isPopupVisible} onlyShowsConfirm={true} onClose={handleDoableReject}>
          {<Text>{t('biker.doableAddInfo')}</Text>}
        </Popup>
      ) : (
        <PopupDoableOrder visible={isPopupVisible} onClose={handleDoableReject} onConfirm={handleDoableConfirm} />
      )}
    </div>
  );
};

export default AbnormalReport;
//
