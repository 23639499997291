import React, { useState, Fragment, useCallback, useEffect } from "react";
import { themeGet } from "@styled-system/theme-get";
import styled from "styled-components";
import { ReactComponent as IconInfo } from "@assets/iconInfo.svg";
import { ReactComponent as IconForbidden } from "@assets/iconForbidden.svg";
import PopupScooterInfo from "@molecules/PopupScooterInfo";
import { getScooterInfoById } from "@apis/dispatch";
import { useTranslation } from "react-i18next";
import { ScooterInfo as ScooterInfoType } from "~/definition/scooter";

export interface ScooterInfoProps {
  scooterId?: string;
  className?: string;
  hasRedDot?: boolean;
  hasForbidden?: boolean;
}

const Container = styled.div`
  display: inline-block;
`;

const Title = styled.span`
  ${themeGet("fonts.button")};
  color: ${themeGet("colors.black")};
  line-height: 24px;
  margin-right: 4px;
  vertical-align: top;
`;

const RedDot = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  vertical-align: top;
  background-color: ${themeGet("colors.error")};
  margin-right: 2px;
`;

const IconForbiddenStyled = styled(IconForbidden)`
  vertical-align: 2px;
  margin-right: 2px;
`;

const ScooterInfo: React.FC<ScooterInfoProps> = ({
  scooterId,
  className,
  hasRedDot = false,
  hasForbidden = false,
}) => {
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [scooterInfo, setScooterInfo] = useState<ScooterInfoType>();
  const { t } = useTranslation();

  const getInfo = useCallback(async () => {
    if (scooterId) {
      const data = await getScooterInfoById(scooterId);
      setScooterInfo(data);
    }
  }, [scooterId]);

  useEffect(() => {
    if (
      isInfoVisible &&
      scooterId &&
      (!scooterInfo || scooterInfo?.scooterId !== scooterId)
    ) {
      getInfo();
    }
  }, [isInfoVisible, getInfo, scooterInfo, scooterId]);

  const onClick = () => {
    setIsInfoVisible(true);
  };

  const onClose = () => {
    setIsInfoVisible(false);
  };

  return (
    <Container className={className}>
      {hasRedDot && <RedDot />}
      {hasForbidden && <IconForbiddenStyled />}
      <Title>{scooterId || t("biker.noScooterIdOrder")}</Title>
      {scooterId && (
        <Fragment>
          <IconInfo width={20} onClick={onClick} />
          <PopupScooterInfo
            title={scooterId}
            scooterInfo={scooterInfo}
            visible={isInfoVisible}
            onClose={onClose}
          />
        </Fragment>
      )}
    </Container>
  );
};

export default ScooterInfo;
