export const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY as string;

interface IMultiLanguage {
  en: string;
  "zh-tw": string;
  [x: string]: string;
}

export const scooterNoBoxIdStatus = "scooterNoBoxIdStatus";


export const scooterStatus = {
  0: { en: "service", "zh-tw": "待維護" },
  1: { en: "maintenance", "zh-tw": "維護中" },
  2: { en: "online", "zh-tw": "上線" },
  3: { en: "occupied", "zh-tw": "租借中" },
  4: { en: "unknown", "zh-tw": "未知問題" },
  5: { en: "offline", "zh-tw": "下線" },
  6: { en: "error", "zh-tw": "出錯" },
  7: { en: "fixing", "zh-tw": "維修中" },
  [scooterNoBoxIdStatus]: { en: "offline", "zh-tw": "目前斷線" }
};

export enum orderType {
  UNKNOWN = 0,
  VAN_DELIVERY = 1,
  VAN_COLLECTION = 2,
  BIKER_ASSIGNMENT = 3,
  BIKER_POOL = 4
}

export enum pinType {
  BIKER_ASSIGNMENT = 0,
  BIKER_POOL = 1,
  SELECTED_BIKER_POOL = 2,
  SCOOTER = 3
}

export enum ReportableItemType {
  abnormalItem = 1,
  missingScooterItem = 2,
  searchableItem = 3
}

export enum dispatchStates {
  newOrder = 0, //待指派 [待指派頁]
  assignedOrder = 1, //待接收 [待接收頁]
  notifiedOrder = 2, //處理中 [處理中頁]
  unfinishedOrder = 3, //未完成 [待驗收頁]
  finishedOrder = 4, //已完成 [待驗收頁]
  abnormalOrder = 5, //異常 [待驗收頁]
  completedOrder = 6, //已驗收 [歷史紀錄頁]
  deleted = 7 //已刪除 [歷史紀錄頁]
}

export const dispatchStateNames = {
  [dispatchStates.newOrder]: { "zh-tw": "待指派", en: "To be assigned" },
  [dispatchStates.assignedOrder]: { "zh-tw": "待接收", en: "To be confirmed" },
  [dispatchStates.notifiedOrder]: { "zh-tw": "處理中", en: "In progress" },
  [dispatchStates.unfinishedOrder]: { "zh-tw": "未完成", en: "Unfinished" },
  [dispatchStates.finishedOrder]: { "zh-tw": "已完成", en: "Finished" },
  [dispatchStates.abnormalOrder]: { "zh-tw": "異常", en: "Abnormal" },
  [dispatchStates.completedOrder]: { "zh-tw": "已驗收", en: "Completed" },
  [dispatchStates.deleted]: { "zh-tw": "已刪除", en: "Deleted" }
};

export enum dispatchTaskPriorityIds {
  NORMAL = "1",
  EMERGENCY = "4"
}

export const dispatchTaskPriority: Record<string, IMultiLanguage> = {
  [dispatchTaskPriorityIds.NORMAL]: { en: "normal", "zh-tw": "一般", color: "#52c41a" },
  [dispatchTaskPriorityIds.EMERGENCY]: { en: "emergency", "zh-tw": "緊急", color: "#f5222d" }
};

export enum scooterControlActions {
  "POWER_ON" = "POWER_ON",
  "POWER_OFF" = "POWER_OFF",
  "OPEN_TRUNK" = "OPEN_TRUNK",
  "OPEN_BAT" = "OPEN_BAT",
  "FLASH" = "FLASH",
  "ALERT_FLASH" = "ALERT_FLASH",
  "STOP_FLASH" = "STOP_FLASH"
}

export enum searchScooterTypes {
  ownedOrder = "ownedOrder",
  othersOrder = "othersOrder",
  reservable = "reservable",
  doable = "doable",
  unavailable = "unavailable"
}

export enum scooterGroups {
  normal = 0,
  dispatch = 3,
  RD = 6
}