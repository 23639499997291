import React, { useState, useEffect, useRef, Fragment, CSSProperties } from 'react';
import { GoogleMap, Marker, useLoadScript, LoadScriptProps } from '@react-google-maps/api';
import { googleMapApiKey, pinType } from '@configs/.';
import LocationIndicator from '@atoms/LocationIndicator';
import poolSvg from './pool.svg';
import assignSvg from './assign.svg';
import selectedAssignSvg from './selectedAssign.svg';
import scooterSvg from './scooter.svg';
import selectedPoolSvg from './selectedPool.svg';
import { Scooter } from '~/definition/scooter';
import { Order } from '~/definition/order';
import { Location } from '~/definition/general';

export interface PinData extends Order {
  pinType: pinType;
}

export interface MapInfoProps {
  onPinClick: (pinData: PinData) => void;
  onMapIdle?: (position: Location) => void;
  assignList?: Order[];
  poolList?: Order[];
  selectedPoolList?: Order[];
  currentUserPosition: Location;
  currentCenterPosition: Location;
  currentOrder?: PinData | null;
  currentScooter?: Scooter | null;
  onMapClick: () => void;
  hasCenterCircle?: boolean;
}

const googleLibraries: LoadScriptProps['libraries'] = ['geometry', 'places', 'visualization'];

const googleMapConfig = {
  zoom: 12,
  mapContainerStyle: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  } as CSSProperties,
  options: {
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    clickableIcons: false,
    zoomControl: false,
  },
};

const pinSvgMap = {
  [pinType.BIKER_ASSIGNMENT]: selectedAssignSvg,
  [pinType.BIKER_POOL]: selectedPoolSvg,
  [pinType.SELECTED_BIKER_POOL]: selectedPoolSvg,
  [pinType.SCOOTER]: scooterSvg,
};

const MapInfo: React.FC<MapInfoProps> = ({
  onPinClick,
  onMapIdle,
  assignList = [],
  poolList = [],
  selectedPoolList = [],
  onMapClick,
  currentOrder = null,
  currentScooter = null,
  hasCenterCircle = false,
  currentUserPosition,
  currentCenterPosition,
}) => {
  const [mapConfig, setMapConfig] = useState({ ...googleMapConfig, center: currentCenterPosition });
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapApiKey,
    libraries: googleLibraries,
  });
  const googleMapRef = useRef<google.maps.Map<Element>>();

  const onMapLoad = (ref: google.maps.Map<Element>) => {
    googleMapRef.current = ref;
    googleMapRef.current.set('gestureHandling', 'greedy');
  };

  const handleOrderOnClick = (pinData: PinData) => {
    onPinClick(pinData);
  };

  const handleMapIdle = () => {
    const mapCenter = googleMapRef.current?.getCenter();
    if (mapCenter) {
      typeof onMapIdle === 'function' && onMapIdle({ lat: mapCenter?.lat(), lng: mapCenter?.lng() });
    }
  };

  useEffect(() => {
    const root = document.getElementById('root');
    if (root) {
      root.style.overflow = 'hidden';
      root.style.position = 'fixed';
    }
    return () => {
      if (root) {
        root.style.overflow = 'auto';
        root.style.position = 'relative';
      }
    };
  }, []);

  useEffect(() => {
    setMapConfig((pre) => ({ ...pre, center: currentCenterPosition }));
  }, []);

  return (
    <Fragment>
      {isLoaded && (
        <GoogleMap onIdle={handleMapIdle} {...mapConfig} onLoad={onMapLoad} onClick={onMapClick}>
          <LocationIndicator
            hasCenterCircle={hasCenterCircle}
            position={currentUserPosition}
            center={googleMapRef.current?.getCenter()}
          />
          <Fragment>
            {assignList.map(
              ({ id, scooterId, scooterLat, scooterLng, ...props }) =>
                currentOrder?.scooterId !== scooterId &&
                scooterLat &&
                scooterLng && (
                  <Marker
                    zIndex={2}
                    key={id}
                    position={{ lat: scooterLat, lng: scooterLng }}
                    icon={assignSvg}
                    onClick={() =>
                      handleOrderOnClick({
                        id,
                        scooterId,
                        scooterLat,
                        scooterLng,
                        pinType: pinType.BIKER_ASSIGNMENT,
                        ...props,
                      })
                    }
                  />
                )
            )}
          </Fragment>
          <Fragment>
            {poolList.map(
              ({ id, scooterId, scooterLat, scooterLng, ...props }) =>
                currentOrder?.scooterId !== scooterId &&
                scooterLat &&
                scooterLng && (
                  <Marker
                    zIndex={2}
                    key={id}
                    position={{ lat: scooterLat, lng: scooterLng }}
                    icon={poolSvg}
                    onClick={() =>
                      handleOrderOnClick({
                        id,
                        scooterId,
                        scooterLat,
                        scooterLng,
                        pinType: pinType.SELECTED_BIKER_POOL,
                        ...props,
                      })
                    }
                  />
                )
            )}
          </Fragment>
          <Fragment>
            {selectedPoolList.map(
              ({ id, scooterId, scooterLat, scooterLng, ...props }) =>
                currentOrder?.scooterId !== scooterId &&
                scooterLat &&
                scooterLng && (
                  <Marker
                    key={id}
                    position={{ lat: scooterLat, lng: scooterLng }}
                    icon={poolSvg}
                    onClick={() =>
                      handleOrderOnClick({
                        id,
                        scooterId,
                        scooterLat,
                        scooterLng,
                        pinType: pinType.SELECTED_BIKER_POOL,
                        ...props,
                      })
                    }
                  />
                )
            )}
          </Fragment>
          <Fragment>
            {currentScooter && currentScooter?.lat && currentScooter?.lng && (
              <Marker
                key={currentScooter.id}
                position={{ lat: currentScooter.lat, lng: currentScooter.lng }}
                icon={pinSvgMap[pinType.SCOOTER]}
              />
            )}
            {currentOrder && currentOrder.pinType && (
              <Marker
                key={currentOrder.id}
                position={{
                  lat: currentOrder.scooterLat,
                  lng: currentOrder.scooterLng,
                }}
                icon={pinSvgMap[currentOrder.pinType]}
              />
            )}
          </Fragment>
        </GoogleMap>
      )}
    </Fragment>
  );
};

export default MapInfo;
