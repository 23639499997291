import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import isNil from "lodash.isnil";

import { getOrders, resetCurrentOrder, selectPoolFilter, selectCurrentOrder } from "@redux/poolOrders";
import { reserveScooter, resetReportScooter, searchScooter, cancelReserveScooter } from "@redux/reportScooter";
import { AppDispatch } from "@redux/store";
import { enqueueNotification } from "@redux/notification";


const useSearchScooter = ({
  searchRef
}: {
  searchRef: React.RefObject<{
    resetFields: () => void;
  }>;
}): {
  onSearch: () => void;
  onReserve: () => void;
  onCancelReserve: () => void;
} => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const filter = useSelector(selectPoolFilter);
  const currentOrder = useSelector(selectCurrentOrder)

  const onSearch = useCallback(async () => {
    dispatch(resetReportScooter());
    const action = await dispatch(getOrders());
    if (getOrders.fulfilled.match(action)) {
      if (isNil(action.payload)) {
        dispatch(resetCurrentOrder());
        if (filter.includeTaskItems.length === 0 && filter.scooterId) {
          dispatch(searchScooter(filter.scooterId));
        }
      } else {
        if (!isNil(currentOrder)) {
          if (isNil(action.payload.find(scooter => scooter.id === currentOrder.id))) {
            dispatch(resetCurrentOrder());
          }
        }
      }
    }
  }, [dispatch, filter, currentOrder])

  const onReserve = useCallback(async () => {
    const action = await dispatch(reserveScooter());
    if (reserveScooter.fulfilled.match(action)) {
      dispatch(enqueueNotification({ message: t("biker.reserveSucceeded"), duration: 2000 }));
    } else if (reserveScooter.rejected.match(action)) {
      dispatch(enqueueNotification({ message: t("biker.reserveFailed"), type: "warning", duration: 2000 }));
    }
  }, [dispatch, t]);

  const onCancelReserve = useCallback(async () => {
    const action = await dispatch(cancelReserveScooter());
    if (cancelReserveScooter.fulfilled.match(action)) {
      dispatch(enqueueNotification({ message: t("biker.cancelReserveSucceeded"), duration: 2000 }));
      dispatch(getOrders());
      searchRef.current?.resetFields();
    } else if (cancelReserveScooter.rejected.match(action)) {
      dispatch(enqueueNotification({ message: t("biker.cancelReserveFailed"), type: "warning", duration: 2000 }));
    }
  }, [dispatch, t, searchRef]);

  return { onSearch, onReserve, onCancelReserve };
};
export default useSearchScooter;
