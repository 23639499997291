import { ParkingLot } from "~/definition/parkinglot";
import { kottos } from './BikerAxios'

export const getParkingLots = () => {
  return kottos.get<{ data: ParkingLot[] }>(`/v2/parkingLots`).then(({ data }) => data.data);
};

export const openInParkingGate = (id: string) => {
  return kottos.post(`/v13/parkingLots/${id}/openInGate`);
};

export const openOutParkingGate = (id: string) => {
  return kottos.post(`/v13/parkingLots/${id}/openOutGate`);
};
