import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import isNil from 'lodash.isnil';

import { getOrders, getCurrentOrder } from '@redux/ownedOrders';
import { getProfile, selectPosition, setPosition } from '@redux/user';
import { enqueueNotification } from '@redux/notification';
import { getEventConfigs, postEventConfig } from '@apis/worker';
import AppInner from './AppInner';
import { getLanguage, getLoginJob, getLogoutJob, subscribeMessage, unsubscribeMessage } from '../services/app';
import { kottos } from '@apis/BikerAxios';
import Loading from '@atoms/Loading';

interface AppProps {
  history: RouteComponentProps['history'];
  location: RouteComponentProps['location'];
}

const App: React.FC<AppProps> = ({ history, location }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const position = useSelector(selectPosition);
  const [isInitiated, setIsInitiated] = useState(false);

  const setEventConfig = async () => {
    try {
      const { intervalTimes } = await getEventConfigs();
      if (intervalTimes) {
        setInterval(() => {
          postEventConfig(position);
        }, intervalTimes * 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      if (!process.env.REACT_APP_IS_LOCAL) {
        try {
          const { language } = await getLanguage();
          kottos.setAcceptLanguage(language);
          i18n.changeLanguage(language.toLowerCase());
        } finally {
          try {
            const { workerToken } = await getLoginJob();
            kottos.setToken(workerToken);
            dispatch(getOrders());
            dispatch(getProfile());
            setEventConfig();
          } catch (error) {
            getLogoutJob({
              error,
              description: 'App: log out due to initialize error',
            });
          }
        }
      } else {
        kottos.setAcceptLanguage('zh-tw');
        kottos.setToken(
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoid29ya2VyIiwicmF3Ijp7ImlkIjoxNTUzfSwiaWF0IjoxNjI0ODUyNDIwLCJuYmYiOjE2MjQ4NTI0MjAsImV4cCI6NDAwMDAwMDAwMH0.UrRsbjCh4x1d-cV4FIkP6k3d7hTgecS9ZOZ2qfVAPnk'
        );
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            dispatch(
              setPosition({
                lng: position.coords.longitude,
                lat: position.coords.latitude,
              })
            );
          });
        } else {
          dispatch(
            setPosition({
              lng: 121.5439708,
              lat: 25.05278,
            })
          );
        }
        dispatch(getProfile());
        dispatch(getOrders());
      }
    };

    const dispatchLocation = (location: { lng: number; lat: number }) => {
      dispatch(setPosition(location));
    };

    const dispatchNotification = (result: any) => {
      const message = result?.aps?.alert?.body || result?.body;

      // NOTE: 接收的單若有更新 maintenance item，重新 load 資料
      if (message === 'ORDER_UPDATED') {
        const matchDetailPage = window.location.pathname.match(/dispatch\/(\d+)\/form/);
        const isInListPage = window.location.pathname === '/dispatch-list';

        if (matchDetailPage) {
          dispatch(getCurrentOrder(matchDetailPage[1])); // order id
        } else if (isInListPage) {
          dispatch(getOrders());
        }
      } else {
        dispatch(enqueueNotification({ message, duration: 5000 }));
        dispatch(getOrders());
      }
    };

    const subLocationFailAction = (error: any) => {
      dispatch(enqueueNotification({ message: t('biker.subLocationFail'), type: 'warning', duration: 2000 }));
    };

    const subNotificationFailAction = (error: any) => {
      dispatch(enqueueNotification({ message: t('biker.subNotificationFail'), type: 'warning', duration: 2000 }));
    };

    let locationListenerId: string | undefined;
    let notificationListenerId: string | undefined;

    (async () => {
      await initialize();
      locationListenerId = subscribeMessage('USER_LOCATION', dispatchLocation, subLocationFailAction);
      notificationListenerId = subscribeMessage('REMOTE_NOTIFICATION', dispatchNotification, subNotificationFailAction);
      setIsInitiated(true);
    })();

    return () => {
      !isNil(locationListenerId) && unsubscribeMessage('USER_LOCATION', locationListenerId);
      !isNil(notificationListenerId) && unsubscribeMessage('REMOTE_NOTIFICATION', notificationListenerId);
    };
  }, []);

  return isInitiated ? <AppInner location={location} history={history} /> : <Loading visible={true} />;
};

export default App;
