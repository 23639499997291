import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface AppStatusState {
  hasJustLogin: boolean;
}

const initialState: AppStatusState = {
  hasJustLogin: true
};

export const appStatueSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateAppStatus: (state, action: PayloadAction<AppStatusState>) => {
      state = { ...state, ...action.payload };
    },
    updateLoginFlag: state => {
      state.hasJustLogin = false;
    },
    resetAppStatue: state => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    }
  }
});

export const { updateAppStatus, updateLoginFlag, resetAppStatue } = appStatueSlice.actions;

export const selectAppStatus = (state: RootState): AppStatusState => state.appStatus;
export const selectHasJustLogin = (state: RootState): boolean => state.appStatus.hasJustLogin;

export default appStatueSlice.reducer;
