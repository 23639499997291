import { useTransition } from "react-spring";

const useFadeIn = (fromBottom = false) => {
  const transitions = useTransition(true, null, {
    from: { transform: fromBottom ? `translate3d(0,100%,0)` : "translate3d(0,0%,0)", opacity: 0 },
    enter: { transform: `translate3d(0,0%,0)`, opacity: 1 },
    leave: { transform: fromBottom ? `translate3d(0,100%,0)` : "translate3d(0,0%,0)", opacity: 0 }
  });

  return { transitions };
};

export default useFadeIn;
