import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getReportableItems } from "@apis/dispatch";
import { RootState } from "./store";
import { ReportItem } from "~/definition/general";

export interface ReportableItemsState {
  abnormalItems: ReportItem[] | null;
  missingScooterItems: ReportItem[] | null;
  searchableItems: ReportItem[] | null;
}

const initialState: ReportableItemsState = {
  abnormalItems: null,
  missingScooterItems: null,
  searchableItems: null
};

export const getAbnormalItems = createAsyncThunk("reportableItems/getAbnormalItems", async (type, thunkApi) => {
  try {
    const data = await getReportableItems(2);
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const getMissingScooterItems = createAsyncThunk("reportableItems/getMissingScooterItems", async (type, thunkApi) => {
  try {
    const data = await getReportableItems(1);
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const getSearchableItems = createAsyncThunk("reportableItems/getSearchableItems", async (type, thunkApi) => {
  try {
    const data = await getReportableItems(3);
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

const reportableItemsSlice = createSlice({
  name: "reportableItems",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAbnormalItems.fulfilled, (state, { payload }) => {
      state.abnormalItems = payload;
    });
    builder.addCase(getMissingScooterItems.fulfilled, (state, { payload }) => {
      state.missingScooterItems = payload;
    });
    builder.addCase(getSearchableItems.fulfilled, (state, { payload }) => {
      state.searchableItems = payload;
    });
  }
});

export const selectAbnormalItems = (state: RootState): ReportItem[] | null => state.reportableItems.abnormalItems;

export const selectMissingScooterItems = (state: RootState): ReportItem[] | null => state.reportableItems.missingScooterItems;

export const selectSearchableItems = (state: RootState): ReportItem[] | null => state.reportableItems.searchableItems;

export default reportableItemsSlice.reducer;
