import React, { useState, Fragment } from "react";
import styled from "styled-components";
import Card from "@atoms/Card";
import Button from "@atoms/Button";
import PopupInputForm from "@molecules/PopupInputForm";
import { themeGet } from "@styled-system/theme-get";
import { useTranslation } from "react-i18next";

export interface CardNoteEditorProps {
  title: string;
  value?: string;
  onChange?: (value: string) => void;
}

const StyledButton = styled(Button)`
  display: inline-block;
  float: right;
  margin-top: -4px;
`;

const Text = styled.div`
  ${themeGet("fonts.body1")};
  color: ${themeGet("colors.black")};
  min-height: 40px;
`;

const CardNoteEditor: React.FC<CardNoteEditorProps> = ({
  title,
  value,
  onChange,
}) => {
  const [text, setText] = useState(value);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const { t } = useTranslation();
  const handleOnConfirm = (value: string) => {
    setText(value || "");
    typeof onChange === "function" && onChange(value || "");
  };

  return (
    <Card
      title={
        <Fragment>
          {title}
          <StyledButton
            shape="capsule"
            color="plan"
            label={t("biker.edit")}
            onClick={() => setIsPopupVisible(true)}
            size="small"
            width="80px"
          />
        </Fragment>
      }
      padding={12}
    >
      <Text>{text}</Text>
      <PopupInputForm
        visible={isPopupVisible}
        fieldName="description"
        defaultValue={text}
        title={title}
        onConfirm={handleOnConfirm}
        onClose={() => setIsPopupVisible(false)}
      />
    </Card>
  );
};

export default CardNoteEditor;
