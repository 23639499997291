import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { height, HeightProps } from "styled-system";
import PullUp from "@atoms/PullUp";
import ItemText from "@atoms/ItemText";
import { useTranslation } from "react-i18next";

export interface PullUpAbnormalProps {
  visible: boolean;
  onMissingScooterClick?: () => void;
  onReportClick: () => void;
  onClose: () => void;
  hasMissingReport?: boolean;
}

const TextStyled = styled.div`
  text-align: center;
  height: 60px;
  line-height: 60px;
  ${themeGet("fonts.body2")};
  color: ${themeGet("colors.black")};
  &:nth-child(1) {
    border-bottom: 1px solid ${themeGet("colors.secondary")};
  }
  &:focus {
    background: ${themeGet("colors.gray300")};
    outline: none;
  }
`;

const PullUpStyled = styled(PullUp)<HeightProps>`
  ${height}
`;

const PullUpAbnormal: React.FC<PullUpAbnormalProps> = ({
  visible,
  onMissingScooterClick,
  onReportClick,
  onClose,
  hasMissingReport = true,
}) => {
  const { t } = useTranslation();

  return (
    <PullUpStyled
      title={t("biker.selectReport")}
      visible={visible}
      onClose={onClose}
      onlyShowsConfirm={true}
      height={hasMissingReport ? 185 : 120}
    >
      {hasMissingReport && (
        <TextStyled onClick={onMissingScooterClick} key={1}>
          {t("biker.missingScooterReport")}
        </TextStyled>
      )}
      <TextStyled key={2} onClick={onReportClick}>
        {t("biker.abnormalReport")}
      </TextStyled>
    </PullUpStyled>
  );
};

export default PullUpAbnormal;
