import React, { useState, ReactNode } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { layout } from 'styled-system';
import { buttonSize, shape, color, disabled, common } from './style';

export interface ButtonStyledProps {
  buttonSize?: 'small' | 'medium';
  disabled?: boolean;
  shape?: 'capsule' | 'round' | 'circle' | 'none';
  color?: 'primary' | 'plan' | 'secondary' | 'warning';
  width?: number | string;
}

export interface ButtonProps {
  size?: 'small' | 'medium';
  disabled?: boolean;
  shape?: 'capsule' | 'round' | 'circle' | 'none';
  color?: 'primary' | 'plan' | 'secondary' | 'warning';
  Icon?: React.FC<React.SVGProps<SVGSVGElement>> | string;
  onClick?: () => void;
  label?: string | ReactNode;
  width?: number | string;
  className?: string;
}

const ButtonStyled = styled(animated.button).withConfig({
  shouldForwardProp: (props, defaultValidatorFn) => !['buttonSize'].includes(props) && defaultValidatorFn(props),
})<ButtonStyledProps>`
  ${common}
  ${buttonSize}
  ${shape}
  ${color}
  ${disabled}
  ${layout}
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
  overflow: hidden;
  /* below: to make overflow:hidden; works with border-radius in safari */
  will-change: transform;
  position: relative;
`;

const Mask = styled(animated.div)`
  background: black;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const IconWrapper = styled.svg<{ shape: ButtonStyledProps['shape'] }>`
  margin-right: ${(props) => (props.shape !== 'circle' ? 8 : 0)};
`;

const Button: React.FC<ButtonProps> = ({
  Icon,
  width,
  onClick,
  label,
  className,
  size = 'medium',
  disabled = false,
  shape = 'round',
  color = 'primary',
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const style = useSpring({ transform: `scale(${isFocus ? 0.9 : 1})` });
  const maskStyle = useSpring({ opacity: isFocus ? 0.1 : 0 });

  const handleOnTouchStart = () => {
    setIsFocus(true);
  };
  const handleOnTouchEnd = () => {
    setIsFocus(false);
  };

  return (
    <ButtonStyled
      style={style}
      onTouchStart={handleOnTouchStart}
      onTouchEnd={handleOnTouchEnd}
      width={width}
      shape={shape}
      color={color}
      buttonSize={size}
      disabled={disabled}
      onClick={onClick || undefined}
      className={className}
    >
      <Mask style={maskStyle} />
      {Icon &&
        (typeof Icon === 'string' ? (
          <img src={Icon} alt={`${label} button icon`} />
        ) : (
          <IconWrapper shape={shape} as={Icon} />
        ))}
      {label}
    </ButtonStyled>
  );
};

export default Button;
