import React from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import { themeGet } from "@styled-system/theme-get";
import { ReactComponent as IconWarning } from "@assets/iconReport.svg";
import { ReactComponent as IconSuccess } from "@assets/iconSuccess.svg";

export interface NotificationProps {
  value: string;
  type?: "success" | "warning" | "normal";
  suffixText?: string;
  onSuffixClick?: () => void;
  className?: string;
}

const NotificationContainer = styled.div<{ type: string }>`
  text-align: "left";
  ${variant({
    prop: "type",
    variants: {
      normal: {
        backgroundColor: "black",
        textAlign: "center",
      },
      success: {
        backgroundColor: "blue400",
      },
      warning: {
        backgroundColor: "error",
      },
    },
  })};
  ${themeGet(`fonts.body1`)};
  color: white;
  padding: 0 13.5px;
  height: 47.5px;
  line-height: 47.5px;
  border-radius: 4px;
`;

const Text = styled.span`
  margin-left: 12px;
  vertical-align: middle;
  flex: 1 1 0;
`;

const TextContainer = styled.span<{ float: string }>`
  float: ${({ float }) => float};
  display: flex;
`;

const SuffixText = styled.span`
  ${themeGet(`fonts.button`)};
  float: right;
  line-height: 47.5px;
  cursor: pointer;
`;

const Notification: React.FC<NotificationProps> = ({
  value,
  type = "normal",
  suffixText,
  className,
  onSuffixClick,
}) => {
  return (
    <NotificationContainer type={type} className={className}>
      <TextContainer float={suffixText ? "left" : "none"}>
        {type === "success" ? (
          <IconSuccess width={20} height={47.5} fill="white" />
        ) : (
          <IconWarning width={20} height={47.5} fill="white" />
        )}
        <Text>{value}</Text>
      </TextContainer>
      <SuffixText onClick={onSuffixClick}>{suffixText}</SuffixText>
    </NotificationContainer>
  );
};

export default Notification;
