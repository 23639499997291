import { configureStore, ThunkAction, Action, getDefaultMiddleware } from "@reduxjs/toolkit";
import userReducer from "./user";
import appStatueReducer from "./appStatus";
import poolOrdersReducer from "./poolOrders";
import parkingLotsReducer from "./parkingLots";
import ownedOrdersReducer from "./ownedOrders";
import notificationReducer from "./notification";
import reportScooterReducer from "./reportScooter";
import reportableItemsReducer from "./reportableItems";

export const store = configureStore({
  reducer: {
    user: userReducer,
    ownedOrders: ownedOrdersReducer,
    poolOrders: poolOrdersReducer,
    appStatus: appStatueReducer,
    notification: notificationReducer,
    reportableItems: reportableItemsReducer,
    reportScooter: reportScooterReducer,
    parkingLots: parkingLotsReducer
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false
  })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
