import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import Button from '@atoms/Button';
import Popup from '@atoms/Popup';
import { themeGet } from '@styled-system/theme-get';
import { ParkingLot } from '~/definition/parkinglot';

export interface PopupParkingGateProps {
  visible: boolean;
  title: string;
  onOpenInGate: (value: string) => void;
  onOpenOutGate: (value: string) => void;
  onClose: () => void;
  parkingLots: ParkingLot[];
}

const Select = styled.select`
  ${themeGet('fonts.body2')};
  background: ${themeGet('colors.gray100')};
  border: none;
  display: block;
  width: 100%;
  height: 48px;
  margin: 24px 0;
  text-align: center;
  text-align-last: center;
  -moz-text-align-last: center;
  font-size: 16px;
`;

const ControlPanel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${themeGet(`fonts.subtitle`)};
  color: ${themeGet(`colors.black`)};
`;
const PopupParkingGate: React.FC<PopupParkingGateProps> = ({
  visible,
  parkingLots = [],
  title,
  onClose,
  onOpenInGate,
  onOpenOutGate,
}) => {
  const [currentParkingLot, setCurrentParkingLot] = useState<string>('default');
  const handleOnOpenInGate = () => {
    if (currentParkingLot !== 'default') {
      onOpenInGate(currentParkingLot);
    }
  };

  const handleOnOpenOutGate = () => {
    if (currentParkingLot !== 'default') {
      onOpenOutGate(currentParkingLot);
    }
  };

  const handleOnSelectChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    setCurrentParkingLot(evt.target.value);
  };

  return (
    <Popup title={title} visible={visible} onClose={onClose} onlyShowsConfirm={true} hideButtons={true}>
      <label htmlFor="cars">Choose a parking Lot:</label>
      <Select onChange={handleOnSelectChange} defaultValue="default">
        <option disabled value="default" key="defaultSelect">
          Please Select a ParkingLot
        </option>
        {parkingLots.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </Select>
      <ControlPanel>
        <Button
          disabled={currentParkingLot === 'default'}
          label={
            <span>
              開啟
              <br />
              入口閘門
            </span>
          }
          onClick={handleOnOpenInGate}
          width="40%"
        />
        <Button
          disabled={currentParkingLot === 'default'}
          label={
            <span>
              開啟
              <br />
              出口閘門
            </span>
          }
          onClick={handleOnOpenOutGate}
          width="40%"
        />
      </ControlPanel>
    </Popup>
  );
};

export default PopupParkingGate;
