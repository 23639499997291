import React, { useMemo } from "react";
import styled from "styled-components";
import Popup from "@atoms/Popup";
import { themeGet } from "@styled-system/theme-get";
import { useTranslation } from "react-i18next";
import { scooterNoBoxIdStatus, scooterStatus } from "@configs/.";

interface ScooterInfo {
  scooterId: string;
  status: keyof typeof scooterStatus;
  groupId: number;
  items: { id: number; name: string; description: string; comment?: string }[];
  battery: number;
  scooterLat: number;
  scooterLng: number;
  boxId?: string;
}

export interface PopupScooterInfoProps {
  visible: boolean;
  title?: string;
  onClose: () => void;
  scooterInfo?: ScooterInfo;
}

const Info = styled.span`
  ${themeGet("fonts.body1")};
  color: ${themeGet("colors.black")};
  display: block;
  text-align: left;
`;

const Label = styled.div`
  font-weight: 500;
  margin-right: 8px;
  margin-top: 8px;
  display: inline-block;
`;

const Text = styled.span``;

const TextNewLine = styled.div`
  margin-top: 8px;
`;

const Item = styled.span`
  display: block;
  margin-left: 4px;
`;

const PopupScooterInfo: React.FC<PopupScooterInfoProps> = ({
  visible,
  title,
  onClose,
  scooterInfo,
}) => {
  const { t, i18n } = useTranslation();
  const language: "en" | "zh-tw" = useMemo(
    () => i18n.language.toLocaleLowerCase(),
    [i18n.language]
  ) as "en" | "zh-tw";
  const { status, groupId, boxId, items, battery, scooterLat, scooterLng } =
    scooterInfo || {};

  return (
    <Popup
      title={title}
      onlyShowsConfirm={true}
      visible={visible}
      onClose={onClose}
    >
      <Info>
        <Label>{t("biker.status")}</Label>
        {status !== undefined && boxId ? (
          <Text>{scooterStatus[status]?.[language]}</Text>
        ) : (
          <Text>{scooterStatus[scooterNoBoxIdStatus]?.[language]}</Text>
        )}
      </Info>
      <Info>
        <Label>{t("biker.group")}</Label>
        <Text>{groupId}</Text>
      </Info>
      <Info>
        <Label>Maintenance Items</Label>
        <TextNewLine>
          {items?.map(({ comment, id, description }) => (
            <Item key={id}>{`${id} ${description}${
              comment ? "-" + comment : ""
            }`}</Item>
          ))}
        </TextNewLine>
      </Info>
      <Info>
        <Label>{t("biker.battery")}</Label>
        <Text>{battery}%</Text>
      </Info>
      <Info>
        <Label>GPS</Label>
        <TextNewLine>lat - {scooterLat}</TextNewLine>
        <TextNewLine>lng - {scooterLng}</TextNewLine>
      </Info>
    </Popup>
  );
};

export default PopupScooterInfo;
