import React, { useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Card from "@atoms/Card";
import ItemText from "@atoms/ItemText";
import Button from "@atoms/Button";
import { ReactComponent as IconPhoto } from "@assets/iconAddPhoto.svg";
import { getTakePhotoJob } from "~/services/app";
import { dataURLtoFile, getBase64 } from "@utils/.";
import { useTranslation } from "react-i18next";
import { enqueueNotification } from "@redux/notification";
import PopupPhoto from "@molecules/PopupPhoto";
import { ReactComponent as IconClose } from "@assets/iconClose.svg";

export interface CardPhotoEditorProps {
  value?: File[];
  max: number;
  onChange?: (photos: File[]) => void;
}

const Image = styled.img`
  width: 100px;
`;

const ImageContainer = styled.div`
  width: 100px;
  height: 100px;
  display: inline-block;
  margin-right: 16px;
  position: relative;
  vertical-align: top;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #1d1d1d1c;
  }
`;

const ButtonStyled = styled(Button)`
  margin-right: 8;
`;

const ImageGroupWrapper = styled.div`
  width: 100%;
  overflow: scroll;
  height: 150px;
  margin: 8px 0;
  padding: 11px 0 0;
`;

const ImageGroup = styled.div`
  width: max-content;
`;

const ButtonDelete = styled.span`
  background-color: #0000001a;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  display: inline-block;
  position: absolute;
  padding: 1px 2px;
  top: -9px;
  right: -7px;
`;

const CardPhotoEditor: React.FC<CardPhotoEditorProps> = ({ value, onChange, max }) => {
  const [base64Data, setBase64Data] = useState<string[]>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPhotoDetail, setShowPhotoDetail] = useState(false);
  const [currentPhoto, setCurrentPhoto] = useState<string | null>(null);

  const handleCamera = async () => {
    const { photos } = await getTakePhotoJob(max - (base64Data?.length || 0));
    typeof onChange === "function" &&
      onChange([
        ...(value || []),
        ...photos?.map((photo, i) => dataURLtoFile(`data:image/jpg;base64, ${photo}`, `photo-${i}.jpg`))
      ]);
  };

  const getSrcData = useCallback(() => {
    if (value) {
      try {
        const getBase64Jobs: Promise<string>[] = value?.map(getBase64);
        Promise.all(getBase64Jobs).then(setBase64Data);
      } catch (error) {
        dispatch(
          enqueueNotification({
            message: t("biker.photoError"),
            duration: 2000,
            type: "warning"
          })
        );
      }
    }
  }, [value]);

  const onDetailClose = () => {
    setShowPhotoDetail(false);
    setCurrentPhoto(null);
  };

  const deletePhoto = (evt: any, index: number) => {
    evt.stopPropagation();
    typeof onChange === "function" && value && onChange([...value.slice(0, index), ...value.slice(index + 1)]);
  };

  const openPhoto = (value: string) => {
    setShowPhotoDetail(true);
    setCurrentPhoto(value);
  };

  useEffect(() => {
    getSrcData();
  }, [getSrcData]);

  return (
    <Card title={t("biker.photo")} padding={12}>
      <ItemText
        title={t("biker.maxPhoto", { max })}
        suffix={
          <ButtonStyled
            label={t("biker.takePhoto")}
            shape="capsule"
            Icon={IconPhoto}
            size="small"
            color="plan"
            width="120px"
            onClick={handleCamera}
          />
        }
      />
      <ImageGroupWrapper>
        <ImageGroup>
          {base64Data?.map((photo, i) => (
            <ImageContainer onClick={() => openPhoto(photo)}>
              <ButtonDelete onClick={evt => deletePhoto(evt, i)}>
                <IconClose width={14} height={14} />
              </ButtonDelete>
              <Image src={photo} alt="mission" />
            </ImageContainer>
          ))}
        </ImageGroup>
      </ImageGroupWrapper>
      <PopupPhoto base64={currentPhoto} visible={showPhotoDetail} onClose={onDetailClose} />
    </Card>
  );
};

export default CardPhotoEditor;
