import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en.json";
import zhTw from "./locales/zh-tw.json";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

// i18n
//   // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
//   // learn more: https://github.com/i18next/i18next-http-backend
//   .use(Backend)
//   // detect user language
//   // learn more: https://github.com/i18next/i18next-browser-languageDetector
//   .use(LanguageDetector)
//   // pass the i18n instance to react-i18next.
//   .use(initReactI18next)
//   // init i18next
//   // for all options read: https://www.i18next.com/overview/configuration-options
//   .init({
//     fallbackLng: "en",
//     debug: true,
//     interpolation: {
//       escapeValue: false // not needed for react as it escapes by default
//     }
//   });

export const resources = {
  en: { translation: en },
  "zh-TW": { translation: zhTw }
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "zh-TW",
    // lng: "zh-TW",
    debug: true,
    resources,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
