import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Circle } from '@react-google-maps/api';
import { OverlayView } from '@react-google-maps/api';
import { Location } from '~/definition/general';
import { themeGet } from '@styled-system/theme-get';

export interface LocationIndicatorProps {
  position: Location;
  center?: google.maps.LatLng;
  hasCenterCircle: boolean;
}

const circleOptions = {
  strokeColor: '#4BC6E8',
  strokeOpacity: 1,
  strokeWeight: 1,
  fillColor: '#4bc6e8',
  fillOpacity: 0.06,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 500,
  zIndex: 1,
};

const Mask = styled.div`
  background-color: ${themeGet('colors.gpsBlueSup')};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const Dot = styled.div`
  background-color: ${themeGet('colors.blue400')};
  width: 15px;
  height: 15px;
  border: 2px solid white;
  border-radius: 50%;
`;

export const IndicatorMapDot: React.FC = () => (
  <Mask>
    <Dot />
  </Mask>
);

const handleOverlayPosition = (offsetWidth: number, offsetHeight: number) => ({
  x: -offsetWidth / 2,
  y: -offsetHeight / 2,
});

const LocationIndicator: React.FC<LocationIndicatorProps> = ({ position, center, hasCenterCircle }) => {
  return position ? (
    <Fragment>
      {hasCenterCircle && (
        <Circle options={circleOptions} center={center || { lat: 25.041969, lng: 121.544902 }} radius={3500} />
      )}
      <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={handleOverlayPosition}
      >
        <IndicatorMapDot />
      </OverlayView>
    </Fragment>
  ) : null;
};

export default LocationIndicator;
