import React from "react";
import styled from "styled-components";
import { BackgroundColorProps, color } from "styled-system";

export interface TagProps {
  color?: string;
  text?: string;
}

const TagContainer = styled.span<BackgroundColorProps>`
  ${color}
  color:white;
  padding: 4px 12px;
`;

const Tag: React.FC<TagProps> = ({ color = "gray700", text = "" }) => {
  return <TagContainer backgroundColor={color}>{text}</TagContainer>;
};

export default Tag;
