import WeMoWebJsBridge from "@wemo/WeMoWebJsBridge";

const postActions = {
  Camera: "Camera",
  Url: "Notification",
  GET_SERVICE_LIST: "GET_SERVICE_LIST",
  GET_LNG: "GET_LNG",
  GET_LOGIN: "GET_LOGIN",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  TAKE_PHOTO: "TAKE_PHOTO",
  LOGOUT: "LOGOUT"
} as const;

const subActions = {
  REMOTE_NOTIFICATION: "REMOTE_NOTIFICATION",
  USER_LOCATION: "USER_LOCATION"
} as const;

const wemoJsBridge = new WeMoWebJsBridge(
  {
    post: Object.values(postActions),
    subscribe: Object.values(subActions)
  },
  false
);

const privateKey = "PRIVATE_KEY";
wemoJsBridge
  .init(privateKey)
  .then(token => {
    console.log("init success", token);

    // ... Do something
  })
  .catch(error => {
    console.log("init failed", error);
    // ... Do something
  });

export default wemoJsBridge;
