import React, { useEffect, useMemo, useState, Fragment } from "react";
import Form, { Field, useForm } from "rc-field-form";
import { themeGet } from "@styled-system/theme-get";
import { useSelector, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { completeOrder, getCurrentOrder, selectCurrentOrder, selectOwnOrderLoading, removeOrder } from "@redux/ownedOrders";
import { getParkingLots, openInParkingGate, openOutParkingGate, selectParkingLots } from "@redux/parkingLots";
import { enqueueNotification } from "@redux/notification";
import { selectPosition } from "@redux/user";
import { AppDispatch } from "@redux/store";
import { controlScooter } from "@apis/scooter";
import { orderType, scooterControlActions } from "@configs/.";
import IconReportProblem from "@assets/iconReportProblem.svg";
import CardPhotoEditor from "@organisms/CardPhotoEditor";
import CardNoteEditor from "@organisms/CardNoteEditor";
import ScooterInfo from "@organisms/ScooterInfo";
import PopupParkingGate from "@molecules/PopupParkingGate";
import PullUpMapLocate from "@molecules/PullUpMapLocate";
import ItemTextOrigin from "@molecules/ItemTextOrigin";
import PullUpAbnormal from "@molecules/PullUpAbnormal";
import { CheckboxGroup } from "@atoms/Checkbox";
import Button from "@atoms/Button";
import Popup from "@atoms/Popup";
import Card from "@atoms/Card";
import Loading from "@atoms/Loading";
import Notification from "@atoms/Notification";

const Container = styled.div`
  padding-bottom: 80px;
  position: relative;
`;

const Header = styled.div`
  background-color: white;
  padding: 12px;
`;

const ControlPanel = styled.div`
  background-color: white;
  padding: 12px 0;
`;

const ButtonStyled = styled(Button)`
  margin: 4px 8px 4px 0;
`;

const Text = styled.span`
  ${themeGet("fonts.body1")};
  color: ${themeGet("colors.black")};
`;

const IconTitle = styled.span`
  vertical-align: bottom;
  line-height: 40px;
  display: inline-block;
  margin-right: 8px;
  margin-left: 12px;
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  background: white;
  padding: 12px;
  width: 100%;
`;

const ScooterInfoStyled = styled(ScooterInfo)`
  margin-bottom: 8px;
`;

const ButtonRemove = styled(Button)`
  transform: scale(1);
  right: 16px;
  top: 4px;
  position: absolute;
`;

const ButtonReport = styled(Button)`
  float: right;
  margin-right: 12px;
`;

const NotificationStyled = styled(Notification)`
  margin: 12px;
  height: auto;
`;

const DispatchForm: React.FC<RouteComponentProps<{ [x: string]: string }>> = ({ match, history }) => {
  const currentOrder = useSelector(selectCurrentOrder);
  const isCompleteOrderLoading = useSelector(selectOwnOrderLoading);
  const { parkingLots } = useSelector(selectParkingLots);
  const position = useSelector(selectPosition);
  const [warningConfig, setWarningConfig] = useState({
    visible: false,
    text: ""
  });
  const [isParkingVisible, setIsParkingVisible] = useState(false);
  const [isLocaterVisible, setIsLocaterVisible] = useState(false);
  const [isReportVisible, setIsReportVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [form] = useForm();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const mapActionTaskId = useMemo(() => currentOrder?.tasks.find(({ actionType }) => actionType === 6)?.taskId, [
    currentOrder?.tasks
  ]);

  const removeThisOrder = async () => {
    const action = await dispatch(removeOrder(currentOrder.id));
    if (removeOrder.fulfilled.match(action)) {
      dispatch(
        enqueueNotification({
          message: t("biker.removeSucceeded"),
          duration: 2000
        })
      );
      history.goBack();
    }
  };

  const onAbnormalReportClick = () => {
    setIsReportVisible(true);
  };

  const onReportClick = () => {
    history.push(`/dispatch/${match.params.id}/abnormal-report`);
  };

  const onMissingScooterClick = () => {
    history.push(`/dispatch/${match.params.id}/missing-scooter-report`);
  };

  const shouldCheckAddress = () => (mapActionTaskId ? false : true);

  const submitForm = async () => {
    const { completedTaskIds, address, comment, photos } = form.getFieldsValue();

    const action = await dispatch(
      completeOrder({
        orderId: currentOrder.id,
        completedTaskIds: completedTaskIds || [],
        address,
        comment,
        photos: photos || []
      })
    );
    if (completeOrder.fulfilled.match(action)) {
      dispatch(
        enqueueNotification({
          message: t("biker.reportSucceeded"),
          duration: 2000
        })
      );
      history.replace(`/dispatch-list`);
    } else if (completeOrder.rejected.match(action)) {
      dispatch(
        enqueueNotification({
          message: t("biker.reportFailed"),
          duration: 2000,
          type: "warning"
        })
      );
    }
  };

  /**
   * 1. 若有任務未完，跳出提示
   * 2. 若有確認車輛位置任務，即使沒有完成，也不用再選位置
   * 3. 若無確認車輛位置任務，請他選位置
   */
  const completeTask = () => {
    const { completedTaskIds } = form.getFieldsValue();
    const uncompletedTask = currentOrder.tasks.length - (completedTaskIds?.length || 0);
    if (uncompletedTask > 0) {
      setWarningConfig({
        visible: true,
        text: t("biker.unfinishedReminder", { taskCount: uncompletedTask })
      });
    } else if (shouldCheckAddress()) {
      setIsLocaterVisible(true);
    } else {
      submitForm();
    }
  };

  const onWarningConfirm = () => {
    if (shouldCheckAddress()) {
      setIsLocaterVisible(true);
    } else {
      submitForm();
    }
    setWarningConfig({ visible: false, text: "" });
  };

  const onTaskCheck = (value: (string | number)[]) => {
    if (mapActionTaskId) {
      if (value.includes(mapActionTaskId) && !address) {
        setIsLocaterVisible(true);
      }
      if (!value.includes(mapActionTaskId)) {
        setAddress("");
      }
    }
  };

  const onAddressChange = (address: string) => {
    setAddress(address);
    if (!mapActionTaskId) {
      submitForm();
    }
  };

  const onMapClose = () => {
    setIsLocaterVisible(false);
  };

  const handleControlScooter = async (action: scooterControlActions) => {
    setIsLoading(true);
    try {
      await controlScooter(currentOrder.scooterId, action);
      setIsLoading(false);
      dispatch(
        enqueueNotification({
          message: t("biker.controlSucceeded"),
          duration: 2000
        })
      );
    } catch (error) {
      setIsLoading(false);
      dispatch(
        enqueueNotification({
          message: t("biker.controlFailed"),
          duration: 2000,
          type: "warning"
        })
      );
    }
  };

  const handleOpenInGate = async (id: string) => {
    const action = await dispatch(openInParkingGate(id));
    if (openInParkingGate.fulfilled.match(action)) {
      dispatch(
        enqueueNotification({
          message: t("biker.controlSucceeded"),
          duration: 2000
        })
      );
    } else if (openInParkingGate.rejected.match(action)) {
      dispatch(
        enqueueNotification({
          message: t("biker.controlFailed"),
          duration: 2000,
          type: "warning"
        })
      );
    }
  };
  const handleOpenOutGate = async (id: string) => {
    const action = await dispatch(openOutParkingGate(id));
    if (openOutParkingGate.fulfilled.match(action)) {
      dispatch(
        enqueueNotification({
          message: t("biker.controlSucceeded"),
          duration: 2000
        })
      );
    } else if (openOutParkingGate.rejected.match(action)) {
      dispatch(
        enqueueNotification({
          message: t("biker.controlFailed"),
          duration: 2000,
          type: "warning"
        })
      );
    }
  };

  useEffect(() => {
    if (parkingLots.length === 0) {
      dispatch(getParkingLots());
    }
  }, []);

  useEffect(() => {
    if (!currentOrder) {
      dispatch(getCurrentOrder(match.params.id));
    }
  }, []);

  useEffect(() => {
    if (!isLocaterVisible && mapActionTaskId && !address) {
      form.setFieldsValue({
        completedTaskIds: form.getFieldValue("completedTaskIds")?.filter((taskId: string) => taskId !== mapActionTaskId)
      });
    }
  }, [isLocaterVisible, address, form, mapActionTaskId]);

  return currentOrder ? (
    <Fragment>
      <Form form={form}>
        <Container>
          <Header>
            <ScooterInfoStyled
              scooterId={currentOrder.scooterId}
              hasRedDot={currentOrder.taskModifiedAt ? true : false}
              hasForbidden={currentOrder.scooterHasNonBikerItem}
            />
            {currentOrder.orderType === orderType.BIKER_POOL && (
              <ButtonRemove
                shape="capsule"
                size="small"
                label={t("biker.remove")}
                color="plan"
                width="30%"
                onClick={removeThisOrder}
              />
            )}
            {currentOrder.origin && <ItemTextOrigin {...currentOrder.origin} />}
            {currentOrder.scooterId && (
              <ControlPanel>
                <ButtonStyled
                  shape="capsule"
                  size="small"
                  label={t("biker.flash")}
                  color="secondary"
                  width="30%"
                  onClick={() => handleControlScooter(scooterControlActions.FLASH)}
                />
                <ButtonStyled
                  shape="capsule"
                  size="small"
                  label={t("biker.trunk")}
                  color="secondary"
                  width="30%"
                  onClick={() => handleControlScooter(scooterControlActions.OPEN_TRUNK)}
                />
                <ButtonStyled
                  shape="capsule"
                  size="small"
                  label={t("biker.boost")}
                  color="secondary"
                  width="30%"
                  onClick={() => handleControlScooter(scooterControlActions.POWER_ON)}
                />
                <ButtonStyled
                  shape="capsule"
                  size="small"
                  label={t("biker.stalled")}
                  color="secondary"
                  width="30%"
                  onClick={() => handleControlScooter(scooterControlActions.POWER_OFF)}
                />
                <ButtonStyled
                  shape="capsule"
                  size="small"
                  label={t("biker.parkingLotGate")}
                  color="secondary"
                  width="62%"
                  onClick={() => setIsParkingVisible(true)}
                />
              </ControlPanel>
            )}
          </Header>
          {currentOrder.managerComment && (
            <Card title={t("biker.managerComment")} padding={12}>
              <Text>{currentOrder.managerComment}</Text>
            </Card>
          )}
          <Card
            title={
              <div>
                <IconTitle>{t("biker.task")} </IconTitle>
                <ButtonReport
                  shape="circle"
                  Icon={IconReportProblem}
                  color="plan"
                  size="small"
                  onClick={onAbnormalReportClick}
                />
                {currentOrder.scooterHasNonBikerItem && (
                  <NotificationStyled type="warning" value={t("biker.hasUnDoableTask")} />
                )}
              </div>
            }
          >
            <Field name="completedTaskIds">
              <CheckboxGroup
                onChange={onTaskCheck}
                defaultValue={currentOrder.tasks?.filter(({ isCompleted }) => isCompleted).map(({ taskId }) => taskId)}
                options={currentOrder.tasks?.map(({ name, taskId, maintenanceItem, addedAfterAssign, description }) => ({
                  label: `${name}  ${maintenanceItem?.id ? "Item ID" + maintenanceItem?.id : ""}`,
                  value: taskId,
                  text: taskId === mapActionTaskId ? address : description ? description : maintenanceItem?.description,
                  background: addedAfterAssign ? "#ffcdd2" : "white"
                }))}
              />
            </Field>
          </Card>
          <Field name="comment" initialValue={""}>
            <CardNoteEditor title={t("biker.comment")} />
          </Field>
          <Field name="photos">
            <CardPhotoEditor max={10} />
          </Field>
          <ButtonContainer>
            <Button
              disabled={isCompleteOrderLoading}
              label={t("biker.report")}
              shape="round"
              width="100%"
              onClick={completeTask}
            />
          </ButtonContainer>
        </Container>
        <Field name="address">
          <PullUpMapLocate
            onChange={onAddressChange}
            key="locate"
            defaultPosition={position}
            visible={isLocaterVisible}
            onClose={onMapClose}
          />
        </Field>
      </Form>
      <PullUpAbnormal
        key="abnormal"
        visible={isReportVisible}
        onReportClick={onReportClick}
        onMissingScooterClick={onMissingScooterClick}
        onClose={() => setIsReportVisible(false)}
      />
      <Popup
        visible={warningConfig.visible}
        onClose={() => setWarningConfig({ visible: false, text: "" })}
        onConfirm={onWarningConfirm}
      >
        {warningConfig.text}
      </Popup>
      <PopupParkingGate
        title="停車場控制"
        onOpenInGate={handleOpenInGate}
        onOpenOutGate={handleOpenOutGate}
        parkingLots={parkingLots}
        visible={isParkingVisible}
        onClose={() => setIsParkingVisible(false)}
      />
      <Loading visible={isLoading || isCompleteOrderLoading} />
    </Fragment>
  ) : (
    <Loading visible={true} />
  );
};

export default DispatchForm;
