import React, { Fragment, useState } from "react";
import styled from "styled-components";
import Card from "@atoms/Card";
import Button from "@atoms/Button";
import ItemTextOrigin from "@molecules/ItemTextOrigin";
import ScooterInfo from "@organisms/ScooterInfo";
import PullUpAbnormal from "@molecules/PullUpAbnormal";
import IconReportProblem from "@assets/iconReportProblem.svg";
import IconLightBall from "@assets/iconLightBall.svg";
import { useTranslation } from "react-i18next";
import { AnimatedValue, ForwardedProps } from "react-spring";
import useFadeIn from "@hooks/useFadeIn";
import { Order } from "~/definition/order";

export interface CardOrderMapProps {
  orderInfo: Order;
  isAddMode: boolean;
  className?: string;
  onReportClick?: () => void;
  onMissingReportClick?: () => void;
  onAcceptOrder?: (id: string) => void;
  onFlashClick?: () => void;
  onViewPoolOrder?: () => void;
  onViewOrder?: () => void;
}

const CardStyled = styled(Card)<{
  className?: string;
}>`
  border-radius: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

const ScooterInfoStyled = styled(ScooterInfo)`
  margin: 4px 0;
`;

const ControlContainer = styled.div`
  width: 35%;
  display: flex;
  justify-content: space-between;
`;

const CardOrderMap: React.FC<CardOrderMapProps> = ({
  orderInfo,
  onFlashClick,
  onReportClick,
  onAcceptOrder,
  onViewPoolOrder,
  onMissingReportClick,
  onViewOrder,
  isAddMode = false,
  className,
}) => {
  const [isReportVisible, setIsReportVisible] = useState(false);
  const { t } = useTranslation();
  const { transitions } = useFadeIn(true);

  const onFlash = () => {
    typeof onFlashClick === "function" && onFlashClick();
  };

  const onAbnormalReportClick = () => {
    setIsReportVisible(true);
  };

  const handleReportClick = () => {
    typeof onReportClick === "function" && onReportClick();
  };

  const handleMissingScooterClick = () => {
    typeof onMissingReportClick === "function" && onMissingReportClick();
  };
  const handleAcceptOrder = () => {
    typeof onAcceptOrder === "function" && onAcceptOrder(orderInfo.id);
  };

  const handleViewPoolOrder = () => {
    typeof onViewPoolOrder === "function" && onViewPoolOrder();
  };

  const handleViewOrder = () => {
    typeof onViewOrder === "function" && onViewOrder();
  };

  return (
    <Fragment>
      {transitions.map(({ props, key }) => (
        <CardStyled className={className} padding={12} style={props} key={key}>
          <ScooterInfoStyled scooterId={orderInfo.scooterId} />
          {orderInfo.origin && <ItemTextOrigin {...orderInfo.origin} />}
          <ButtonContainer>
            {isAddMode ? (
              <Fragment>
                <Button
                  shape="circle"
                  Icon={IconReportProblem}
                  color="plan"
                  size="small"
                  onClick={onAbnormalReportClick}
                />
                <Button
                  shape="round"
                  label={t("biker.addOrder")}
                  color="plan"
                  size="small"
                  width="40%"
                  onClick={handleAcceptOrder}
                />
                <Button
                  shape="round"
                  label={t("biker.checkOrder")}
                  color="primary"
                  size="small"
                  width="40%"
                  onClick={handleViewPoolOrder}
                />
              </Fragment>
            ) : (
              <Fragment>
                <ControlContainer>
                  <Button
                    shape="circle"
                    Icon={IconReportProblem}
                    color="plan"
                    size="small"
                    onClick={onAbnormalReportClick}
                  />
                  <Button
                    shape="circle"
                    Icon={IconLightBall}
                    color="secondary"
                    size="small"
                    onClick={onFlash}
                  />
                </ControlContainer>
                <Button
                  shape="round"
                  label={t("biker.checkOrder")}
                  color="primary"
                  size="small"
                  width="40%"
                  onClick={handleViewOrder}
                />
              </Fragment>
            )}
          </ButtonContainer>
          <PullUpAbnormal
            visible={isReportVisible}
            onClose={() => setIsReportVisible(false)}
            onReportClick={handleReportClick}
            onMissingScooterClick={handleMissingScooterClick}
          />
        </CardStyled>
      ))}
    </Fragment>
  );
};

export default CardOrderMap;
