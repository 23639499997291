import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { color, border, BackgroundColorProps, BorderColorProps, ColorProps, BackgroundProps } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';
import { ReactComponent as IconCheck } from '@assets/iconCheck.svg';
import { ReactComponent as IconDash } from '@assets/iconDash.svg';

export interface CheckboxProps {
  label: string;
  text?: string;
  value: string | number;
  checked?: boolean;
  onChange?: (evt: ChangeEvent<HTMLInputElement>) => void;
  onSuffixClick?: (props: { value?: string | number; label: string }) => void;
  suffix?: ReactNode;
  disabled?: boolean;
  className?: string;
  defaultChecked?: boolean;
  background?: BackgroundProps['background'];
  indeterminate?: boolean;
}

const CheckboxStyled = styled.label<BackgroundColorProps>`
  -webkit-tap-highlight-color: transparent;
  min-height: 60px;
  display: block;
  line-height: 60px;
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  padding: 0 12px;
  /* NOTE: why not backgroundColor:
    ERROR: Attempted import error: 'backgroundColor' is not exported from 'styled-system'. 
   */
  ${color}
  &:not(:last-child) {
    border-bottom: 1px solid ${themeGet('colors.secondary')};
  }
`;

const CheckMark = styled.div<BackgroundColorProps & BorderColorProps>`
  display: inline-block;
  vertical-align: -7px;
  margin-right: 5px;
  position: relative;
  cursor: pointer;
  height: 18px;
  width: 18px;
  border: 2px solid ${themeGet(`colors.secondaryGrey`)};
  ${color}
  ${border}
`;

const InfoContainer = styled.div`
  display: inline-block;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 13.5px;
  justify-content: center;
  flex: 1 1 auto;
  width: 60%;
`;

const Text = styled.div`
  ${themeGet('fonts.body2')};
  color: ${themeGet('colors.secVariant')};
  line-height: 1;
  display: inline-block;
  padding-bottom: 8px;
`;

const CheckboxInput = styled.input<{ id: string }>`
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  cursor: pointer;
  margin: 0;
  height: 100%;
  width: 100%;

  /* & ~ svg {
    display: none;
  }

  &:checked ~ svg {
    display: block;
  } */
`;

const Suffix = styled.div`
  display: inline-block;
`;

const IconStyled = styled.svg.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['visible'].includes(prop) && defaultValidatorFn(prop),
})<{ visible?: boolean }>`
  width: 14px;
  height: 14px;
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

const LabelStyled = styled.label<ColorProps & { htmlFor: string | number }>`
  ${themeGet('fonts.subtitle')};
  color: ${themeGet('colors.black')};
  ${color}
`;

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const {
    label,
    value,
    text,
    checked,
    defaultChecked,
    onChange,
    disabled,
    className,
    suffix,
    background,
    onSuffixClick,
    indeterminate,
  } = props;
  const [isChecked, setIsChecked] = useState(defaultChecked || checked);

  const handleOnChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if (!indeterminate) setIsChecked(evt.target.checked);
    typeof onChange === 'function' && onChange(evt);
  };

  const handleOnSuffixClick = () => {
    typeof onSuffixClick === 'function' && onSuffixClick({ value, label });
  };

  useEffect(() => {
    if (typeof checked === 'boolean') {
      setIsChecked(checked);
    }
  }, [checked]);

  return (
    <CheckboxStyled className={className} backgroundColor={background}>
      <CheckMark
        backgroundColor={disabled ? 'secondary' : isChecked || indeterminate ? 'primary' : 'white'}
        borderColor={isChecked || indeterminate ? 'primary' : ''}
      >
        <CheckboxInput
          id={value as string}
          disabled={disabled}
          type="checkbox"
          onChange={handleOnChange}
          value={value}
          checked={isChecked}
        />
        <IconStyled as={IconCheck} visible={isChecked} />
        <IconStyled as={IconDash} visible={indeterminate} />
      </CheckMark>
      <InfoContainer>
        <LabelStyled htmlFor={value as string} color={disabled ? 'secVariant' : ''}>
          {label}
        </LabelStyled>
        {text && <Text>{text}</Text>}
      </InfoContainer>
      <Suffix onClick={handleOnSuffixClick}>{suffix}</Suffix>
    </CheckboxStyled>
  );
};

const defaultProps: Partial<CheckboxProps> = {
  disabled: false,
  background: 'white',
  indeterminate: false,
};
Checkbox.defaultProps = defaultProps;

export default Checkbox;
